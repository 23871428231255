import { cx } from "@withjuly/frontend-common";
import { ProfileDemographics, Platform } from "@withjuly/fabric";
import {
	FacebookLogo,
	InstagramLogo,
	TiktokLogo,
	TwitchLogo,
	YoutubeLogo,
} from "@withjuly/julycons";

export const calculateAgeData = (demographics?: ProfileDemographics) => {
	const age: Record<string, number> = {};
	if (!demographics) return age;
	Array.from(Object.entries(demographics?.male ?? {})).forEach(
		([key, value]) => {
			age[key] = value;
		},
	);
	Array.from(Object.entries(demographics?.female ?? {})).forEach(
		([key, value]) => {
			if (age[key]) {
				age[key] += value;
			} else {
				age[key] = value;
			}
		},
	);
	return age;
};

export const calculateGenderData = (demographics?: ProfileDemographics) => {
	if (!demographics) return { male: 0, female: 0 };
	const totalMale = Array.from(Object.entries(demographics?.male ?? {})).reduce(
		(acc, [_, value]) => acc + value,
		0,
	);

	const totalFemale = Array.from(
		Object.entries(demographics?.female ?? {}),
	).reduce((acc, [_, value]) => acc + value, 0);

	return {
		male: totalMale,
		female: totalFemale,
	};
};

export const getPlatformDisplay = (platform: string) => {
	if (platform === Platform.INSTAGRAM) {
		return "Instagram";
	} else if (platform === Platform.TIKTOK) {
		return "TikTok";
	} else if (platform === Platform.YOUTUBE) {
		return "YouTube";
	} else if (platform === Platform.FACEBOOK) {
		return "Facebook";
	} else {
		return "";
	}
};

export const getPlatformLogo = (platform: Platform) => {
	switch (platform) {
		case Platform.TIKTOK:
		case Platform.TIKTOK_NOAUTH:
			return (
				<TiktokLogo
					className={cx("text-theme-text-primary h-[20px] w-[20px]")}
				/>
			);
		case Platform.YOUTUBE:
		case Platform.YOUTUBE_NOAUTH:
			return (
				<YoutubeLogo
					className={cx("text-theme-text-primary h-[20px] w-[20px]")}
				/>
			);
		case Platform.FACEBOOK:
			return (
				<FacebookLogo
					className={cx("text-theme-text-primary h-[20px] w-[20px]")}
				/>
			);
		case Platform.TWITCH:
			return (
				<TwitchLogo
					className={cx("text-theme-text-primary h-[20px] w-[20px]")}
				/>
			);
		default:
			return (
				<InstagramLogo
					className={cx("text-theme-text-primary h-[20px] w-[20px]")}
				/>
			);
	}
};

export const parseLinks = (input: string): (string | JSX.Element)[] => {
	const urlRegex = /(https?:\/\/[^\s]+)/g;

	return input.split(urlRegex).map((part, index) =>
		urlRegex.test(part) ? (
			<a
				key={index}
				href={part}
				target="_blank"
				rel="noopener noreferrer"
				className="text-theme-text-secondary underline"
			>
				{part}
			</a>
		) : (
			part
		),
	);
};
