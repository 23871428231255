import {
	AllThemes,
	MediaKit,
	MediaKitHighlightedPosts,
	MediaKitPlatformBlock,
	Platform,
} from "@withjuly/fabric";
import {
	FacebookLogo,
	GlobeRealistic,
	InstagramLogo,
	Tiktok,
	TwitchLogo,
	User,
	YoutubeLogo,
} from "@withjuly/julycons";
import { HighlightedPosts } from "./HighlightedPosts";
import { cx, useWindowSize } from "@withjuly/frontend-common";
import Image from "next/image";
import { useMemo, useState } from "react";
import { Content } from "./PlatformContent";
import { parseLinks } from "~/components/Agency/utils";

interface HeaderProps {
	mediaKit: MediaKit;
	isPreview?: boolean;
	isMobilePreview?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
	mediaKit,
	isPreview,
	isMobilePreview,
}) => {
	const [isAboutExpanded, setIsAboutExpanded] = useState(() => false);
	const isLongAbout = useMemo(() => {
		if (mediaKit.about.length > 400) {
			return true;
		} else {
			return false;
		}
	}, [mediaKit.about]);

	const window = useWindowSize();
	const isHeaderWidthSmall = useMemo(() => {
		if ((window.width ?? 1125) > 1126) {
			return false;
		} else {
			return true;
		}
	}, [window]);

	const enabledBlocks = mediaKit.blocks.filter(
		(block) =>
			block.enabled &&
			(block.type === "instagram" ||
				block.type === "tiktok" ||
				block.type === "facebook" ||
				block.type === "twitch" ||
				block.type === "youtube"),
	) as MediaKitPlatformBlock[];
	const totalAudience = enabledBlocks.reduce((total, block) => {
		const followers = block.fields.stats.find(
			(stat) => stat.name === "followers" || stat.name === "subscribers",
		);

		if (followers?.type === "value") {
			return total + followers.value;
		} else {
			return total;
		}
	}, 0);

	const platformWithHighestFollowers = useMemo(
		() =>
			enabledBlocks.reduce((highest, block) => {
				let highestFollowers = 0;
				const highestFollowersStat = highest?.fields.stats.find(
					(stat) => stat.name === "followers" || stat.name === "subscribers",
				);
				if (highestFollowersStat?.type === "value") {
					highestFollowers = highestFollowersStat.value;
				}

				const followers = block.fields.stats.find(
					(stat) => stat.name === "followers" || stat.name === "subscribers",
				);
				if (followers?.type === "value") {
					return followers.value > highestFollowers ? block : highest;
				} else {
					return highest;
				}
			}, enabledBlocks[0]),
		[enabledBlocks],
	);

	const countrySums = enabledBlocks.reduce(
		(total, block) => {
			const countries = block.fields.stats.find(
				(stat) => stat.name === "country",
			);
			if (countries?.type === "pie") {
				for (const [country, count] of Object.entries(
					countries.data as Record<string, number>,
				)) {
					total = {
						...total,
						[country]: (total[country] || 0) + count,
					};
				}
			}
			return total;
		},
		{} as Record<string, number>,
	);
	const mostPopularCountry = Object.entries(countrySums).reduce(
		(highest, [country, count]) => {
			return count > highest.count ? { country, count } : highest;
		},
		{ country: "", count: 0 },
	);
	if (mostPopularCountry.country.toLowerCase() === "united states") {
		mostPopularCountry.country = "USA";
	} else if (mostPopularCountry.country.toLowerCase() === "united kingdom") {
		mostPopularCountry.country = "UK";
	} else if (
		mostPopularCountry.country.toLowerCase() === "republic of ireland"
	) {
		mostPopularCountry.country = "Ireland";
	}

	return (
		<div
			className={cx(
				"flex w-full max-w-[1200px] justify-center gap-8 md:justify-between",
				isPreview && "lg:justify-between",
				isMobilePreview && "md:justify-center lg:justify-center",
			)}
		>
			<div
				className={cx(
					"flex flex-col items-center justify-center gap-4 md:items-start",
					isPreview && "lg:items-start",
					isMobilePreview && "md:items-center lg:items-center",
				)}
			>
				<div
					className={cx(
						"flex flex-col items-center justify-center gap-6 md:items-start",
						isPreview && "lg:items-start",
						isMobilePreview && "md:items-center lg:items-center",
					)}
				>
					<div>
						{mediaKit.headshot ? (
							<div
								className={cx(
									"relative h-[128px] w-[128px] rounded-full object-cover object-center md:h-[160px] md:w-[160px] lg:h-[218px] lg:w-[218px]",
									isPreview && "h-[160px] w-[160px] lg:h-[160px] lg:w-[160px]",
									isMobilePreview &&
										"h-[128px] w-[128px] md:h-[128px] md:w-[128px] lg:h-[128px] lg:w-[128px]",
								)}
							>
								<Image
									src={mediaKit.headshot.url}
									alt={`${mediaKit.name}'s profile picture`}
									fill
									className={cx("rounded-full object-cover object-center")}
								/>
							</div>
						) : null}
					</div>
					<div
						className={cx(
							"flex flex-col flex-wrap items-center justify-center md:items-start md:gap-2 lg:flex-row lg:items-end lg:justify-start",
							isPreview && "lg:flex-col lg:items-start",
							isMobilePreview &&
								"md:items-center lg:items-center lg:justify-center",
						)}
					>
						<h1
							className={cx(
								"font-inter text-theme-text-header text-center text-[40px] font-bold md:text-left md:text-5xl lg:text-[64px]",
								isPreview && "lg:text-5xl",
								isMobilePreview && "text-center md:text-[40px] lg:text-[40px]",
							)}
						>
							{mediaKit.name}
						</h1>
						<p className="text-bold-lg md:text-bold-xl text-theme-text-secondary md:py-0 lg:py-1">
							{mediaKit.pronouns}
						</p>
					</div>
				</div>
				<div
					className={cx(
						"flex flex-col items-center justify-center gap-6 md:items-start",
						isMobilePreview && "md:items-center",
					)}
				>
					{mediaKit.tags !== "" ? (
						<div
							className={cx(
								"flex max-w-[640px] flex-wrap items-center justify-center gap-2 md:justify-start",
								isMobilePreview && "md:justify-center",
							)}
						>
							{mediaKit.tags.split(",").length > 0
								? mediaKit.tags
										.split(",")
										.filter((t) => t.trim() !== "")
										.map((tag, i) => (
											<div
												key={`tag-${i}`}
												className="text-theme-text-button bg-theme-button-fill rounded-full px-3 py-[6px]"
											>
												<p
													className={cx(
														"text-body-sm lg:text-body-xl capitalize",
														isPreview && "lg:text-body-sm",
													)}
												>
													{tag}
												</p>
											</div>
										))
								: null}
						</div>
					) : null}
					<div
						className={cx(
							"flex flex-col gap-4 md:items-start lg:items-start lg:gap-6",
							isMobilePreview && "lg:items-center",
						)}
					>
						<div
							className={cx(
								"flex items-center justify-center gap-6 md:justify-start md:gap-6 lg:justify-start lg:gap-6",
								isMobilePreview && "flex-wrap lg:justify-center",
							)}
						>
							{mediaKit.headerStats.split(",").includes("total") ? (
								<div
									className={cx(
										"flex flex-col items-center gap-1 md:items-start lg:pr-4",
										isMobilePreview && "lg:items-center",
									)}
								>
									<p
										className={cx(
											"text-body-sm lg:text-body-xl text-theme-text-secondary hidden lg:block",
											isPreview && "lg:text-body-sm",
											isMobilePreview && "lg:hidden",
										)}
									>
										Total Audience
									</p>
									<p
										className={cx(
											"text-body-sm lg:text-body-xl text-theme-text-secondary block lg:hidden",
											isMobilePreview && "lg:text-body-sm lg:block",
										)}
									>
										Audience
									</p>
									<div className="flex items-center justify-start gap-2">
										<User
											className={cx(
												"text-theme-text-primary md:h-5 md:w-5 lg:h-8 lg:w-8",
												isPreview && "lg:h-5 lg:w-5",
											)}
										/>
										<p
											className={cx(
												"text-theme-text-primary text-lg font-semibold lg:text-2xl",
												isPreview && "lg:text-lg",
												isHeaderWidthSmall && "lg:text-lg",
											)}
										>
											{totalAudience.toLocaleString("en-US")}
										</p>
									</div>
								</div>
							) : null}
							{mediaKit.headerStats.split(",").includes("platform") ? (
								<div
									className={cx(
										"flex flex-col items-center gap-1 md:items-start lg:pr-4",
										isMobilePreview && "lg:items-center",
									)}
								>
									<p
										className={cx(
											"text-body-sm lg:text-body-xl text-theme-text-secondary lg:block",
											isPreview && "lg:text-body-sm",
										)}
									>
										Largest Platform
									</p>
									<a
										href={
											platformWithHighestFollowers?.fields.accountUrl ?? "#"
										}
										target="_blank"
										className="flex items-center justify-start gap-2"
									>
										{platformWithHighestFollowers?.type === "instagram" ? (
											<>
												<InstagramLogo
													className={cx(
														"md:h-5 md:w-5 lg:h-8 lg:w-8",
														isPreview && "lg:h-5 lg:w-5",
													)}
												/>
												<p
													className={cx(
														"text-theme-text-primary text-lg font-semibold lg:text-2xl",
														isPreview && "lg:text-lg",
														isHeaderWidthSmall && "lg:text-lg",
													)}
												>
													Instagram
												</p>
											</>
										) : null}

										{platformWithHighestFollowers?.type === "youtube" ? (
											<>
												<YoutubeLogo
													className={cx(
														"md:h-5 md:w-5 lg:h-8 lg:w-8",
														isPreview && "lg:h-5 lg:w-5",
													)}
												/>
												<p
													className={cx(
														"text-theme-text-primary text-lg font-semibold lg:text-2xl",
														isPreview && "lg:text-lg",
														isHeaderWidthSmall && "lg:text-lg",
													)}
												>
													YouTube
												</p>
											</>
										) : null}

										{platformWithHighestFollowers?.type === "tiktok" ? (
											<>
												<Tiktok
													className={cx(
														"md:h-5 md:w-5 lg:h-8 lg:w-8",
														isPreview && "lg:h-5 lg:w-5",
														"text-theme-text-primary",
													)}
												/>

												<p
													className={cx(
														"text-theme-text-primary text-lg font-semibold lg:text-2xl",
														isPreview && "lg:text-lg",
														isHeaderWidthSmall && "lg:text-lg",
													)}
												>
													TikTok
												</p>
											</>
										) : null}
										{platformWithHighestFollowers?.type === "facebook" ? (
											<>
												<FacebookLogo
													className={cx(
														"md:h-5 md:w-5 lg:h-8 lg:w-8",
														isPreview && "lg:h-5 lg:w-5",
														"text-theme-text-primary",
													)}
												/>

												<p
													className={cx(
														"text-theme-text-primary text-lg font-semibold lg:text-2xl",
														isPreview && "lg:text-lg",
														isHeaderWidthSmall && "lg:text-lg",
													)}
												>
													Facebook
												</p>
											</>
										) : null}

										{platformWithHighestFollowers?.type === "twitch" ? (
											<>
												<TwitchLogo
													className={cx(
														"md:h-5 md:w-5 lg:h-8 lg:w-8",
														isPreview && "lg:h-5 lg:w-5",
														"text-theme-text-primary",
													)}
												/>

												<p
													className={cx(
														"text-theme-text-primary text-lg font-semibold lg:text-2xl",
														isPreview && "lg:text-lg",
														isHeaderWidthSmall && "lg:text-lg",
													)}
												>
													Twitch
												</p>
											</>
										) : null}
									</a>
								</div>
							) : null}
							{mediaKit.talentLocation &&
							mediaKit.headerStats.split(",").includes("location") ? (
								<div
									className={cx(
										"flex flex-col items-center gap-1 md:items-start",
										isMobilePreview && "lg:items-center",
									)}
								>
									<p
										className={cx(
											"text-body-sm lg:text-body-xl text-theme-text-secondary lg:block",
											isPreview && "lg:text-body-sm",
										)}
									>
										Location
									</p>
									<div className="flex items-center justify-start gap-2">
										<GlobeRealistic
											className={cx(
												"text-theme-text-primary md:h-5 md:w-5 lg:h-8 lg:w-8",
												isPreview && "lg:h-5 lg:w-5",
											)}
										/>
										<p
											className={cx(
												"text-theme-text-primary text-lg font-semibold lg:text-2xl",
												isPreview && "lg:text-lg",
												isHeaderWidthSmall && "lg:text-lg",
											)}
										>
											{mediaKit.talentLocation}
										</p>
									</div>
								</div>
							) : null}
						</div>
						{mediaKit.isAboutEnabled && mediaKit.about !== "" ? (
							<div
								className={cx(
									"flex flex-col items-center gap-2 md:items-start",
									isMobilePreview && "md:items-center",
								)}
							>
								<p
									className={cx(
										"text-body-xl text-theme-text-primary mt-4 w-full max-w-[540px] whitespace-break-spaces break-words text-center md:mt-0 md:text-left",
										isLongAbout && !isAboutExpanded && "line-clamp-4",
										isMobilePreview && "md:text-center",
									)}
								>
									{parseLinks(mediaKit.about)}
								</p>
								{isLongAbout ? (
									<button
										className={cx(
											"text-bold-lg lg:text-bold-xl text-theme-text-secondary hover:text-theme-text-primary transition-all",
											isPreview && "lg:text-bold-lg",
										)}
										onClick={() => setIsAboutExpanded((prev) => !prev)}
									>
										{isAboutExpanded ? "Show less" : "Show more"}
									</button>
								) : null}
							</div>
						) : null}
					</div>
				</div>
			</div>
			<div
				className={cx(
					"hidden self-center md:flex",
					isMobilePreview && "lg:hidden",
					!mediaKit.showHighlightedPosts && "hidden",
				)}
			>
				{mediaKit.showHighlightedPosts &&
				mediaKit.highlightedPosts.length === 0 &&
				platformWithHighestFollowers ? (
					<HighlightedPosts
						isPreview={isPreview}
						mediaKit={mediaKit}
						block={platformWithHighestFollowers}
						theme={mediaKit.theme}
					/>
				) : (
					<HighlightedCustomPosts
						isPreview={isPreview}
						mediaKit={mediaKit}
						posts={mediaKit.highlightedPosts}
						theme={mediaKit.theme}
					/>
				)}
			</div>
		</div>
	);
};

export enum METRIC {
	LIKES = "likes",
	COMMENTS = "comments",
	SHARES = "shares",
	SAVES = "saves",
	PLAYS = "plays",
}

interface HighlightedCustomPostsProps {
	mediaKit: MediaKit;
	posts: MediaKitHighlightedPosts;
	theme: AllThemes;
	isPreview?: boolean;
}

export const HighlightedCustomPosts: React.FC<HighlightedCustomPostsProps> = ({
	posts,
	theme,
	isPreview,
}) => {
	const [currentPostIndex, setCurrentPostIndex] = useState(0);
	const CurrentPost = useMemo(() => {
		const post = posts[currentPostIndex];
		if (!post) {
			return undefined;
		}

		let metrics: Record<string, number> = {};
		if (post.platform === Platform.INSTAGRAM) {
			metrics = {
				[METRIC.LIKES]: post.likeCount ?? 0,
				[METRIC.PLAYS]: post.viewCount ?? 0,
				[METRIC.SHARES]: post.shareCount ?? 0,
			};
		} else if (post.platform === Platform.TIKTOK) {
			metrics = {
				[METRIC.LIKES]: post.likeCount ?? 0,
				[METRIC.PLAYS]: post.viewCount ?? 0,
				[METRIC.COMMENTS]: post.commentsCount ?? 0,
			};
		} else {
			metrics = {
				[METRIC.LIKES]: post.likeCount ?? 0,
				[METRIC.COMMENTS]: post.commentsCount ?? 0,
				[METRIC.PLAYS]: post.viewCount ?? 0,
			};
		}
		const mediaUrl = post.thumbnailUrl;
		const postUrl = post.postUrl;
		const imageType: string | undefined =
			post.mediaType && post.mediaType === "Instagram Reel" ? "VIDEO" : "IMAGE";

		if (!mediaUrl) {
			return undefined;
		}

		return (
			<Content
				key={`post-${currentPostIndex}`}
				platform={
					post.platform as
						| "instagram"
						| "tiktok"
						| "youtube"
						| "facebook"
						| "twitch"
				}
				mediaUrl={mediaUrl}
				url={postUrl ?? ""}
				metrics={metrics}
				type={imageType}
				isHighlight={true}
				theme={theme}
				setPostIndex={setCurrentPostIndex}
				currentIndex={currentPostIndex}
				totalPosts={posts.length}
				isPreview={isPreview}
			/>
		);
	}, [posts, currentPostIndex, theme, isPreview]);

	if (!CurrentPost) {
		return null;
	}

	return CurrentPost;
};
