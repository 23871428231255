import {
	CURRENCY_LIST,
	MediaKitRate,
	MediaKitRatesBlock,
	UpsertMediaKitRateSchema,
} from "@withjuly/fabric";
import { forwardRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import { trpc } from "~/components/Utility/trpc";
import { useZodForm } from "~/utils/hooks/zod-form";
import {
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
	restrictToParentElement,
	restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { useUpdateMediaKitRatesOrder } from "~/utils/api/query/mediakit";
import { ActiveEditorType } from "~/pages/creator/mediakit";
import { IconButton, Button, Modal, ScrollArea } from "@withjuly/solisv2";
import {
	ArrowLeft,
	Plus,
	Trash,
	DotsSixVertical,
} from "@withjuly/julycons/bold";
import { ZodInput } from "~/components/Input/ZodInput";

interface RatesEditorProps {
	block: MediaKitRatesBlock;
	onClickBack: (editor: ActiveEditorType) => void;
	currency: string;
}

export const RatesEditor: React.FC<RatesEditorProps> = ({
	block,
	onClickBack,
	currency,
}) => {
	const [isUpserting, setIsUpserting] = useState(false);
	const [editingRate, setEditingRate] = useState<MediaKitRate>();
	const updateOrder = useUpdateMediaKitRatesOrder();
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 250,
				tolerance: 8,
			},
		}),
		useSensor(KeyboardSensor),
	);

	const onDragEnd = async (event: DragEndEvent) => {
		const { active, over } = event;

		const overBlock = block.rates.findIndex((b) => b.uuid === over?.id);

		// Move the block within the local array
		//
		// Note that we also do this update optimistically in react-query's
		// onMutate, however that only comes in two ticks after the drag end event,
		// which causes things to jump around.
		const oldIndex = block.rates.findIndex((b) => b.uuid === active.id);
		const activeBlock = block.rates.splice(oldIndex, 1)[0];
		if (activeBlock) {
			block.rates.splice(overBlock, 0, activeBlock);
			block.rates.forEach((b, i) => (b.order = i));
			block.rates.sort((a, b) => a.order - b.order);
		}

		updateOrder.mutate({ uuid: active.id as string, order: overBlock ?? 0 });
	};

	return (
		<>
			<div className="flex w-full flex-col rounded-lg">
				<div className="flex w-full items-center gap-4 px-8 py-7">
					<IconButton
						icon={ArrowLeft}
						size="sm"
						variant="secondary"
						onClick={() => onClickBack("none")}
					/>
					<p className="text-header-xl font-repro">Rates</p>
				</div>
				<div className="bg-stroke-secondary h-px w-full" />
				<ScrollArea className="max-h-[calc(100vh_-_152px)] max-w-[480px]">
					<div className="flex w-full flex-col gap-2 p-6 ">
						<DndContext
							onDragEnd={onDragEnd}
							sensors={sensors}
							modifiers={[restrictToVerticalAxis, restrictToParentElement]}
						>
							<SortableContext items={block.rates.map((rate) => rate.uuid)}>
								{block.rates.map((rate) => (
									<SortableRate
										key={rate.uuid}
										rate={rate}
										setIsUpserting={setIsUpserting}
										setEditingRate={setEditingRate}
										currency={currency}
									/>
								))}
							</SortableContext>
						</DndContext>

						<Button
							leadingIcon={Plus}
							size="md"
							variant="blank"
							onClick={() => setIsUpserting(true)}
							className="min-h-10"
						>
							Add Rate
						</Button>
					</div>
				</ScrollArea>
			</div>

			<UpsertRateModal
				rate={editingRate}
				isOpen={isUpserting}
				setIsOpen={(isOpen) => {
					if (!isOpen) {
						setEditingRate(undefined);
					}

					setIsUpserting(isOpen);
				}}
				currency={currency}
			/>
		</>
	);
};

interface RateProps {
	rate: MediaKitRate;
	setIsUpserting: (isUpserting: boolean) => void;
	setEditingRate: (rate: MediaKitRate) => void;
	currency: string;
}

const SortableRate: React.FC<RateProps> = ({
	rate,
	setIsUpserting,
	setEditingRate,
	currency,
}) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: rate.uuid });

	return (
		<div
			ref={setNodeRef}
			{...listeners}
			{...attributes}
			style={{
				transform: CSS.Translate.toString(transform),
				transition,
				opacity: isDragging ? 0.75 : 1,
			}}
		>
			<Rate
				rate={rate}
				setIsUpserting={setIsUpserting}
				setEditingRate={setEditingRate}
				currency={currency}
			/>
		</div>
	);
};

const Rate = forwardRef<
	HTMLButtonElement,
	React.ButtonHTMLAttributes<HTMLButtonElement> & RateProps
>(({ rate, setIsUpserting, setEditingRate, currency, ...props }, ref) => {
	const utils = trpc.useContext();
	const deleteRate = trpc.mediaKit.deleteRate.useMutation({
		onSuccess: () => utils.mediaKit.get.invalidate(),
	});
	const getPriceDisplay = (price: string) => {
		const priceNum = Number(price);
		if (isNaN(priceNum)) {
			return price;
		}

		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: currency ?? "USD",
		});

		return formatter.format(priceNum);
	};

	return (
		<button
			key={rate.uuid}
			className="bg-surface-hover-1 hover:bg-surface-hover-2 flex h-12 w-full items-center justify-between gap-4 rounded-lg p-4 transition-all"
			onClick={() => {
				setIsUpserting(true);
				setEditingRate(rate);
			}}
			{...props}
			ref={ref}
		>
			<div className="flex items-center gap-4">
				<DotsSixVertical />
				<p className="text-paragraph-sm font-repro max-w-40 truncate">
					{rate.title}
				</p>
			</div>
			<div className="flex items-center gap-4">
				<p className="font-repro text-paragraph-sm">
					{getPriceDisplay(rate.price)}
				</p>
				<IconButton
					icon={Trash}
					variant="secondary"
					size="sm"
					onClick={(e) => {
						e.stopPropagation();
						deleteRate.mutate({ uuid: rate.uuid });
					}}
				/>
			</div>
		</button>
	);
});
Rate.displayName = "Rate";

interface UpsertRateFormProps {
	rate?: MediaKitRate;
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	currency: string;
}

const UpsertRateModal: React.FC<UpsertRateFormProps> = ({
	rate,
	isOpen,
	setIsOpen,
	currency,
}) => {
	const utils = trpc.useContext();
	const upsertRate = trpc.mediaKit.upsertRate.useMutation({
		onSuccess: () => {
			utils.mediaKit.get.invalidate();
			setIsOpen(false);
		},
	});
	const form = useZodForm({
		schema: UpsertMediaKitRateSchema,
		values: {
			uuid: rate?.uuid,
			title: rate?.title ?? "",
			price: rate?.price ?? "",
		},
		submit: async (data) => {
			upsertRate.mutate(
				{
					...data,
				},
				{
					onSuccess: () => form.reset(),
				},
			);
		},
		mode: "onChange",
	});

	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen}>
			<Modal.Header title={rate ? "Edit rate" : "Add rate"} />

			<Modal.Body>
				<FormProvider {...form}>
					<form className="flex w-full flex-col gap-6">
						<div className="flex items-center gap-4">
							<div className="w-3/4">
								<ZodInput
									label="Item"
									name="title"
									placeholder="Add deliverable"
								/>
							</div>
							<div>
								<ZodInput
									name="price"
									label="Price"
									placeholder="0.00"
									type="number"
									leadingIcon={() => (
										<div>
											{
												CURRENCY_LIST.find((curr) => curr.code === currency)
													?.symbol
											}
										</div>
									)}
								/>
							</div>
						</div>
					</form>
				</FormProvider>
			</Modal.Body>
			<Modal.Footer
				primaryLabel="Save"
				onPrimaryClicked={() => {
					form.onSubmit();
				}}
				buttons="primary"
				isPrimaryDisabled={false}
			/>
		</Modal.Root>
	);
};
