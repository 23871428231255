import { z } from "zod";

export const CurrencySchema = z.enum([
	"USD",
	"CAD",
	"GBP",
	"EUR",
	"CHF",
	"JPY",
	"INR",
	"AUD",
	"CNY",
]);
export type Currency = z.infer<typeof CurrencySchema>;
