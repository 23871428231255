import { Theme as ThemeType } from "@withjuly/fabric";

/*
 * LIGHT THEMES
 *
 * "--theme-text-primary": "14 19 22",
 * "--theme-text-secondary": "109 127 139",
 * "--theme-text-button": "255 255 255",
 * "--theme-background": "255 255 255",
 * "--theme-fill": "255 255 255",
 * "--theme-graph-primary": COLOR/6,
 * "--theme-graph-secondary": COLOR/8,
 * "--theme-stroke": "171 186 196",
 * "--theme-button-fill": COLOR/6,
 * "--theme-button-fill-secondary": COLOR/8,
 */

/*
 * DARK THEMES
 *
 * "--theme-text-primary": "255 255 255",
 * "--theme-text-secondary": "171 186 196",
 * "--theme-text-button": COLOR/7,
 * "--theme-background": "14 19 22",
 * "--theme-fill": "18 23 28",
 * "--theme-graph-primary": COLOR/7,
 * "--theme-graph-secondary": COLOR/4,
 * "--theme-stroke": "32 40 48",
 * "--theme-button-fill": COLOR/4,
 * "--theme-button-fill-secondary": COLOR/1,
 */

type ThemeVariable = `${number} ${number} ${number}`;

interface Theme {
	"--theme-text-primary": ThemeVariable;
	"--theme-text-secondary": ThemeVariable;
	"--theme-text-header": ThemeVariable;
	"--theme-text-button": ThemeVariable;
	"--theme-background": ThemeVariable;
	"--theme-fill": ThemeVariable;
	"--theme-graph-primary": ThemeVariable;
	"--theme-graph-secondary": ThemeVariable;
	"--theme-stroke": ThemeVariable;
	"--theme-button-fill": ThemeVariable;
	"--theme-button-fill-secondary": ThemeVariable;
}

const JULY_LIGHT: Theme = {
	"--theme-text-primary": "14 19 22",
	"--theme-text-secondary": "109 127 139",
	"--theme-text-header": "14 19 22",
	"--theme-text-button": "255 255 255",
	"--theme-background": "255 255 255",
	"--theme-fill": "255 255 255",
	"--theme-graph-primary": "5 161 196",
	"--theme-graph-secondary": "157 238 255",
	"--theme-stroke": "171 186 196",
	"--theme-button-fill": "5 161 196",
	"--theme-button-fill-secondary": "157 238 255",
};

const JULY_DARK: Theme = {
	"--theme-text-primary": "255 255 255",
	"--theme-text-header": "255 255 255",
	"--theme-text-secondary": "171 186 196",
	"--theme-text-button": "48 218 255",
	"--theme-background": "14 19 22",
	"--theme-fill": "18 23 28",
	"--theme-graph-primary": "48 218 255",
	"--theme-graph-secondary": "24 61 88",
	"--theme-stroke": "32 40 48",
	"--theme-button-fill": "24 61 88",
	"--theme-button-fill-secondary": "14 31 50",
};

const GREEN_LIGHT: Theme = {
	"--theme-text-primary": "14 19 22",
	"--theme-text-header": "14 19 22",
	"--theme-text-secondary": "109 127 139",
	"--theme-text-button": "255 255 255",
	"--theme-background": "255 255 255",
	"--theme-fill": "255 255 255",
	"--theme-graph-primary": "54 162 90",
	"--theme-graph-secondary": "137 255 176",
	"--theme-stroke": "171 186 196",
	"--theme-button-fill": "54 162 90",
	"--theme-button-fill-secondary": "137 255 176",
};

const GREEN_DARK: Theme = {
	"--theme-text-primary": "255 255 255",
	"--theme-text-header": "255 255 255",
	"--theme-text-secondary": "171 186 196",
	"--theme-text-button": "69 218 119",
	"--theme-background": "14 19 22",
	"--theme-fill": "18 23 28",
	"--theme-graph-primary": "69 218 119",
	"--theme-graph-secondary": "28 66 28",
	"--theme-stroke": "32 40 48",
	"--theme-button-fill": "28 66 28",
	"--theme-button-fill-secondary": "19 39 20",
};

const YELLOW_LIGHT: Theme = {
	"--theme-text-primary": "14 19 22",
	"--theme-text-header": "14 19 22",
	"--theme-text-secondary": "109 127 139",
	"--theme-text-button": "255 255 255",
	"--theme-background": "255 255 255",
	"--theme-fill": "255 255 255",
	"--theme-graph-primary": "170 93 21",
	"--theme-graph-secondary": "244 175 110",
	"--theme-stroke": "171 186 196",
	"--theme-button-fill": "170 93 21",
	"--theme-button-fill-secondary": "244 175 110",
};

const YELLOW_DARK: Theme = {
	"--theme-text-primary": "255 255 255",
	"--theme-text-header": "255 255 255",
	"--theme-text-secondary": "171 186 196",
	"--theme-text-button": "238 145 59",
	"--theme-background": "14 19 22",
	"--theme-fill": "18 23 28",
	"--theme-graph-primary": "238 145 59",
	"--theme-graph-secondary": "82 56 27",
	"--theme-stroke": "32 40 48",
	"--theme-button-fill": "82 56 27",
	"--theme-button-fill-secondary": "42 31 21",
};

const RED_LIGHT: Theme = {
	"--theme-text-primary": "14 19 22",
	"--theme-text-header": "14 19 22",
	"--theme-text-secondary": "109 127 139",
	"--theme-text-button": "255 255 255",
	"--theme-background": "255 255 255",
	"--theme-fill": "255 255 255",
	"--theme-graph-primary": "183 32 39",
	"--theme-graph-secondary": "255 122 129",
	"--theme-stroke": "171 186 196",
	"--theme-button-fill": "183 32 39",
	"--theme-button-fill-secondary": "255 122 129",
};

const RED_DARK: Theme = {
	"--theme-text-primary": "255 255 255",
	"--theme-text-header": "255 255 255",
	"--theme-text-secondary": "171 186 196",
	"--theme-text-button": "245 46 56",
	"--theme-background": "14 19 22",
	"--theme-fill": "18 23 28",
	"--theme-graph-primary": "245 46 56",
	"--theme-graph-secondary": "85 25 42",
	"--theme-stroke": "32 40 48",
	"--theme-button-fill": "85 25 42",
	"--theme-button-fill-secondary": "47 19 30",
};

const PINK_LIGHT: Theme = {
	"--theme-text-primary": "14 19 22",
	"--theme-text-header": "14 19 22",
	"--theme-text-secondary": "109 127 139",
	"--theme-text-button": "255 255 255",
	"--theme-background": "255 255 255",
	"--theme-fill": "255 255 255",
	"--theme-graph-primary": "176 15 90",
	"--theme-graph-secondary": "255 139 193",
	"--theme-stroke": "171 186 196",
	"--theme-button-fill": "176 15 90",
	"--theme-button-fill-secondary": "255 139 193",
};

const PINK_DARK: Theme = {
	"--theme-text-primary": "255 255 255",
	"--theme-text-header": "255 255 255",
	"--theme-text-secondary": "171 186 196",
	"--theme-text-button": "244 34 132",
	"--theme-background": "14 19 22",
	"--theme-fill": "18 23 28",
	"--theme-graph-primary": "244 34 132",
	"--theme-graph-secondary": "95 25 65",
	"--theme-stroke": "32 40 48",
	"--theme-button-fill": "95 25 65",
	"--theme-button-fill-secondary": "47 19 37",
};

const PURPLE_LIGHT: Theme = {
	"--theme-text-primary": "14 19 22",
	"--theme-text-header": "14 19 22",
	"--theme-text-secondary": "109 127 139",
	"--theme-text-button": "255 255 255",
	"--theme-background": "255 255 255",
	"--theme-fill": "255 255 255",
	"--theme-graph-primary": "84 59 235",
	"--theme-graph-secondary": "176 156 255",
	"--theme-stroke": "171 186 196",
	"--theme-button-fill": "84 59 235",
	"--theme-button-fill-secondary": "176 156 255",
};

const PURPLE_DARK: Theme = {
	"--theme-text-primary": "255 255 255",
	"--theme-text-header": "255 255 255",
	"--theme-text-secondary": "171 186 196",
	"--theme-text-button": "145 117 255",
	"--theme-background": "14 19 22",
	"--theme-fill": "18 23 28",
	"--theme-graph-primary": "145 117 255",
	"--theme-graph-secondary": "55 33 141",
	"--theme-stroke": "32 40 48",
	"--theme-button-fill": "55 33 141",
	"--theme-button-fill-secondary": "35 28 65",
};

const BLACK_LIGHT: Theme = {
	"--theme-text-primary": "14 19 22",
	"--theme-text-header": "14 19 22",
	"--theme-text-secondary": "109 127 139",
	"--theme-text-button": "255 255 255",
	"--theme-background": "255 255 255",
	"--theme-fill": "255 255 255",
	"--theme-graph-primary": "18 23 28",
	"--theme-graph-secondary": "171 186 196",
	"--theme-stroke": "171 186 196",
	"--theme-button-fill": "109 127 139",
	"--theme-button-fill-secondary": "219 230 238",
};

const BLACK_DARK: Theme = {
	"--theme-text-primary": "255 255 255",
	"--theme-text-header": "255 255 255",
	"--theme-text-secondary": "171 186 196",
	"--theme-text-button": "255 255 255",
	"--theme-background": "14 19 22",
	"--theme-fill": "18 23 28",
	"--theme-graph-primary": "255 255 255",
	"--theme-graph-secondary": "32 40 48",
	"--theme-stroke": "32 40 48",
	"--theme-button-fill": "32 40 48",
	"--theme-button-fill-secondary": "24 28 32",
};

const NO_NAME_TALENT: Theme = {
	"--theme-text-primary": "0 0 0",
	"--theme-text-header": "44 129 124",
	"--theme-text-secondary": "90 90 90",
	"--theme-text-button": "255 255 255",
	"--theme-background": "217 217 217",
	"--theme-fill": "230 230 230",
	"--theme-graph-primary": "104 184 180",
	"--theme-graph-secondary": "196 220 219",
	"--theme-stroke": "205 205 205",
	"--theme-button-fill": "0 0 0",
	"--theme-button-fill-secondary": "240 240 240",
};

const SIXTEENTH: Theme = {
	"--theme-text-primary": "27 69 51",
	"--theme-text-header": "27 69 51",
	"--theme-text-secondary": "27 69 51",
	"--theme-text-button": "252 242 227",
	"--theme-background": "250 242 229",
	"--theme-fill": "250 242 229",
	"--theme-graph-primary": "27 69 51",
	"--theme-graph-secondary": "180 216 188",
	"--theme-stroke": "27 69 51",
	"--theme-button-fill": "27 69 51",
	"--theme-button-fill-secondary": "200 229 207",
};

const BOWIE: Theme = {
	"--theme-text-primary": "0 0 0",
	"--theme-text-header": "0 0 0",
	"--theme-text-secondary": "97 97 97",
	"--theme-text-button": "0 0 0",
	"--theme-background": "255 255 255",
	"--theme-fill": "251 248 245",
	"--theme-graph-primary": "214 179 145",
	"--theme-graph-secondary": "255 255 255",
	"--theme-stroke": "227 215 202",
	"--theme-button-fill": "214 179 144",
	"--theme-button-fill-secondary": "236 218 196",
};

const LE_FIZZ: Theme = {
	"--theme-text-primary": "0 0 0",
	"--theme-text-header": "0 0 0",
	"--theme-text-secondary": "127 127 127",
	"--theme-text-button": "0 0 0",
	"--theme-background": "255 255 255",
	"--theme-fill": "251 250 241",
	"--theme-graph-primary": "255 162 23",
	"--theme-graph-secondary": "255 226 183",
	"--theme-stroke": "255 231 195",
	"--theme-button-fill": "255 201 61",
	"--theme-button-fill-secondary": "255 230 177",
};

const NEXT_STEP_TALENT: Theme = {
	"--theme-text-primary": "255 255 255",
	"--theme-text-header": "255 255 255",
	"--theme-text-secondary": "255 255 255",
	"--theme-text-button": "255 255 255",
	"--theme-background": "16 16 16",
	"--theme-fill": "0 29 31",
	"--theme-graph-primary": "3 194 203",
	"--theme-graph-secondary": "0 73 77",
	"--theme-stroke": "1 72 75",
	"--theme-button-fill": "8 194 203",
	"--theme-button-fill-secondary": "0 64 66",
};

const UNTITLED_SECRET: Theme = {
	"--theme-text-primary": "0 0 0",
	"--theme-text-header": "0 0 0",
	"--theme-text-secondary": "97 97 97",
	"--theme-text-button": "255 255 255",
	"--theme-background": "255 255 255",
	"--theme-fill": "255 255 255",
	"--theme-graph-primary": "0 0 0",
	"--theme-graph-secondary": "228 228 228",
	"--theme-stroke": "218 218 218",
	"--theme-button-fill": "0 0 0",
	"--theme-button-fill-secondary": "242 242 242",
};

const DINER: Theme = {
	"--theme-text-primary": "255 255 255",
	"--theme-text-secondary": "255 255 255",
	"--theme-text-header": "255 255 255",
	"--theme-text-button": "0 27 52",
	"--theme-background": "9 55 97",
	"--theme-fill": "10 64 112",
	"--theme-graph-primary": "239 185 93",
	"--theme-graph-secondary": "116 74 4",
	"--theme-stroke": "12 74 131",
	"--theme-button-fill": "239 185 93",
	"--theme-button-fill-secondary": "12 74 131",
};

const SOCALITE: Theme = {
	"--theme-text-primary": "255 255 255",
	"--theme-text-secondary": "255 255 255",
	"--theme-text-header": "255 255 255",
	"--theme-text-button": "238 238 238",
	"--theme-background": "42 57 68",
	"--theme-fill": "42 57 68",
	"--theme-graph-primary": "255 255 255",
	"--theme-graph-secondary": "84 107 124",
	"--theme-stroke": "60 84 101",
	"--theme-button-fill": "67 112 146",
	"--theme-button-fill-secondary": "44 67 84",
};

const CREATOR_CAMP: Theme = {
	"--theme-text-primary": "36 36 36",
	"--theme-text-secondary": "99 97 95",
	"--theme-text-header": "36 36 36",
	"--theme-text-button": "241 235 221",
	"--theme-background": "245 240 230",
	"--theme-fill": "241 235 221",
	"--theme-graph-primary": "36 36 36",
	"--theme-graph-secondary": "227 215 186",
	"--theme-stroke": "231 221 197",
	"--theme-button-fill": "36 36 36",
	"--theme-button-fill-secondary": "230 221 201",
};

const TOUCHPOINT_TALENT: Theme = {
	"--theme-text-primary": "104 0 24",
	"--theme-text-secondary": "104 0 24",
	"--theme-text-header": "104 0 24",
	"--theme-text-button": "192 0 45",
	"--theme-background": "245 241 208",
	"--theme-fill": "245 241 208",
	"--theme-graph-primary": "221 0 52",
	"--theme-graph-secondary": "103 0 24",
	"--theme-stroke": "227 220 162",
	"--theme-button-fill": "255 200 169",
	"--theme-button-fill-secondary": "239 233 190",
};

type Themes = Record<string, Record<"light" | "dark", Theme>>;
const THEMES: Themes = {
	july: {
		dark: JULY_DARK,
		light: JULY_LIGHT,
	},
	green: {
		light: GREEN_LIGHT,
		dark: GREEN_DARK,
	},
	yellow: {
		light: YELLOW_LIGHT,
		dark: YELLOW_DARK,
	},
	red: {
		light: RED_LIGHT,
		dark: RED_DARK,
	},
	pink: {
		light: PINK_LIGHT,
		dark: PINK_DARK,
	},
	purple: {
		light: PURPLE_LIGHT,
		dark: PURPLE_DARK,
	},
	black: {
		light: BLACK_LIGHT,
		dark: BLACK_DARK,
	},
	"no name talent": {
		light: NO_NAME_TALENT,
		dark: NO_NAME_TALENT,
	},
	sixteenth: {
		light: SIXTEENTH,
		dark: SIXTEENTH,
	},
	bowie: {
		light: BOWIE,
		dark: BOWIE,
	},
	"le fizz": {
		light: LE_FIZZ,
		dark: LE_FIZZ,
	},
	"next step talent": {
		light: NEXT_STEP_TALENT,
		dark: NEXT_STEP_TALENT,
	},
	"untitled secret": {
		light: UNTITLED_SECRET,
		dark: UNTITLED_SECRET,
	},
	diner: {
		light: DINER,
		dark: DINER,
	},
	socalite: {
		light: SOCALITE,
		dark: SOCALITE,
	},
	creatorcamp: {
		light: CREATOR_CAMP,
		dark: CREATOR_CAMP,
	},
	touchpoint: {
		light: TOUCHPOINT_TALENT,
		dark: TOUCHPOINT_TALENT,
	},
} as const;

export const getThemeStyles = (
	theme: string,
	mode: "dark" | "light",
	customTheme: ThemeType | null,
) => {
	if (customTheme) {
		return `:root {
  --theme-text-primary: ${customTheme.textPrimary};
  --theme-text-secondary: ${customTheme.textSecondary};
  --theme-text-button: ${customTheme.textButton};
  --theme-text-header: ${customTheme.textHeader};
  --theme-background: ${customTheme.background};
  --theme-fill: ${customTheme.fill};
  --theme-graph-primary: ${customTheme.graphPrimary};
  --theme-graph-secondary: ${customTheme.graphSecondary};
  --theme-stroke: ${customTheme.stroke};
  --theme-button-fill: ${customTheme.buttonFill};
  --theme-button-fill-secondary: ${customTheme.buttonFillSecondary};
			}`;
	} else {
		const themeVariables = THEMES[theme]?.[mode] ?? JULY_DARK;

		const styles = Object.entries(themeVariables)
			.map(([key, value]) => {
				return `${key}: ${value};`;
			})
			.join("\n");

		return `:root { ${styles} }`;
	}
};

export const getRgbThemeStyles = (
	theme: string,
	mode: "dark" | "light",
	customTheme: ThemeType | null,
): ThemeType => {
	if (customTheme) {
		return customTheme;
	} else {
		const themeVars = THEMES[theme]?.[mode] ?? JULY_DARK;
		return {
			name: "",
			textPrimary: themeVars["--theme-text-primary"],
			textSecondary: themeVars["--theme-text-secondary"],
			textHeader: themeVars["--theme-text-header"],
			textButton: themeVars["--theme-text-button"],
			buttonFill: themeVars["--theme-button-fill"],
			buttonFillSecondary: themeVars["--theme-button-fill-secondary"],
			fill: themeVars["--theme-fill"],
			graphPrimary: themeVars["--theme-graph-primary"],
			graphSecondary: themeVars["--theme-graph-secondary"],
			stroke: themeVars["--theme-stroke"],
			background: themeVars["--theme-background"],
		};
	}
};

export const getTextAndBackgroundColor = (
	theme: string,
	mode: "dark" | "light",
) => {
	const themeVariables = THEMES[theme]?.[mode] ?? JULY_DARK;
	return [
		`rgb(${themeVariables["--theme-text-primary"]})`,
		`rgb(${themeVariables["--theme-fill"]})`,
	];
};

export const getBackgroundFillColor = (
	theme: string,
	mode: "dark" | "light",
	customTheme: ThemeType | null,
) => {
	if (customTheme) {
		return customTheme.fill;
	} else {
		const themeVariables = THEMES[theme]?.[mode] ?? JULY_DARK;
		return themeVariables["--theme-fill"];
	}
};
