import {
	AllThemes,
	CustomContentImageSize,
	CustomContentImageSizeType,
	CustomProfile,
	MediaKitStat,
} from "@withjuly/fabric";
import { ExternalLink } from "@withjuly/julycons";
import { StatCard } from "./StatCard";
import { BarStat } from "./BarStat";
import { cx } from "@withjuly/frontend-common";
import { PieStat } from "./PieStat";

interface CustomProfileStatsProps {
	profile: CustomProfile;
	theme: AllThemes;
	isPreview?: boolean;
	isMobilePreview?: boolean;
	darkMode: boolean;
}

export const CustomProfileStats: React.FC<CustomProfileStatsProps> = ({
	profile,
	theme,
	isPreview,
	isMobilePreview,
}) => {
	const ageStat: MediaKitStat & { type: "bar" } = {
		type: "bar",
		name: "age",
		title: "Audience Age",
		enabled: profile.metadata?.demographics?.age ? true : false,
		data: profile.metadata?.demographics?.age ?? {},
	};
	const countryStat: MediaKitStat & { type: "bar" } = {
		type: "bar",
		name: "country",
		title: "Audience Country",
		enabled: profile.metadata?.demographics?.country ? true : false,
		data: profile.metadata?.demographics?.country ?? {},
	};
	const genderStat: MediaKitStat & { type: "pie" } = {
		type: "pie",
		name: "gender",
		title: "Audience Gender",
		enabled: profile.metadata?.demographics?.gender ? true : false,
		data: profile.metadata?.demographics?.gender ?? {},
	};

	return (
		<div
			className={cx(
				"animate-open-fade-move-down flex h-full w-full flex-col gap-16",
				isPreview && !isMobilePreview && "min-w-[680px]",
			)}
		>
			<div className="flex w-full flex-col gap-4">
				<div className="flex items-end justify-between gap-3 ">
					{profile.username ? (
						profile.accountUrl ? (
							<a
								href={profile.accountUrl}
								target="_blank"
								className="flex items-center gap-4"
							>
								<p className="text-theme-text-primary text-xl font-semibold">
									@{profile.username}
								</p>
							</a>
						) : (
							<p className="text-theme-text-primary text-xl font-semibold">
								@{profile.username}
							</p>
						)
					) : null}

					{profile.accountUrl ? (
						<a
							href={profile.accountUrl}
							target="_blank"
							rel="noreferrer"
							className="text-theme-text-secondary flex items-center gap-1 pb-[3px] font-medium"
						>
							Go to profile <ExternalLink />
						</a>
					) : null}
				</div>
				{profile.content && profile.content.length > 0 ? (
					<div
						className={cx(
							"flex gap-4 overflow-x-scroll pr-4",
							!isPreview && "lg:pr-0",
						)}
					>
						{profile.content
							?.sort((a, b) => a.order - b.order)
							.map((content) => (
								<CustomProfileContent
									key={content.uuid}
									theme={theme}
									url={content.contentUrl}
									mediaUrl={content.imageUrl}
									description={content.description ?? ""}
									imageSize={content.imageSize}
								/>
							))}
					</div>
				) : null}
			</div>
			<div className="flex h-full w-full flex-col gap-4">
				<div className="flex w-full items-center justify-between">
					<p className="text-theme-text-primary text-xl font-semibold leading-[1.30em]">
						{profile.title} Stats
					</p>
				</div>

				<div
					className={cx(
						"flex h-fit flex-wrap items-center gap-4 md:gap-0 md:gap-y-4  lg:flex-nowrap lg:gap-4",
						isPreview &&
							!isMobilePreview &&
							"lg:flex lg:flex-wrap lg:gap-0 lg:gap-y-4",
						isMobilePreview &&
							"//md:flex-wrap //lg:flex-wrap w-full flex-col flex-nowrap",
					)}
				>
					{profile.metadata?.stats
						?.filter((stat) => stat.enabled)
						.map((stat, i) => {
							return (
								<div
									key={`instagram-stat-${i}`}
									className={cx(
										"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:odd:last:pr-0 md:even:pl-2 lg:p-0 lg:odd:pr-0 lg:even:pl-0",
										isPreview &&
											!isMobilePreview &&
											"lg:odd:pr-2 lg:odd:last:p-0 lg:even:pl-2",
										isMobilePreview &&
											"w-full basis-auto md:odd:pr-0 md:even:pl-0",
										!isPreview && "lg:basis-auto",
									)}
								>
									<StatCard
										title={stat.displayTitle}
										value={String(stat.value)}
									/>
								</div>
							);
						})}
				</div>
				<div
					className={cx(
						"flex flex-col flex-wrap-reverse items-start gap-y-4 md:flex-row lg:flex-nowrap lg:gap-4",
						isPreview && "lg:flex-wrap-reverse lg:gap-0 lg:gap-y-4",
						isMobilePreview && "md:flex-wrap-reverse lg:flex-wrap-reverse",
					)}
				>
					{genderStat && genderStat.enabled ? (
						<div
							className={cx(
								"w-full",
								!isPreview && "md:basis-1/2 lg:basis-auto",
								isPreview && "lg:basis-1/2",
								isMobilePreview && "md:basis-full lg:basis-full",
							)}
						>
							<div
								className={cx(
									"w-full md:pr-2 lg:pr-0",
									isPreview && !isMobilePreview && "lg:pr-2",
								)}
							>
								<PieStat
									theme={theme}
									stat={genderStat}
									platform="instagram"
									isPreview={isPreview}
								/>
							</div>
						</div>
					) : null}
					{ageStat && ageStat.enabled ? (
						<div
							className={cx(
								"w-full",
								!isPreview && "md:basis-1/2 lg:basis-auto",
								isPreview && "lg:basis-1/2",
								isMobilePreview && "md:basis-full lg:basis-full",
							)}
						>
							<div
								className={cx(
									"w-full md:pl-2 lg:pl-0",
									isPreview && !isMobilePreview && "lg:pl-2",
								)}
							>
								<BarStat
									isPreview={isPreview}
									stat={ageStat}
									truncateKey="45+"
									demographic="age"
								/>
							</div>
						</div>
					) : null}
					{countryStat && countryStat.enabled ? (
						<div
							className={cx(
								"w-full md:basis-full lg:basis-auto",
								isPreview && "md:basis-full lg:basis-full",
							)}
						>
							<BarStat
								isPreview={isPreview}
								stat={countryStat}
								sortBy="value"
								demographic="location"
							/>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

interface CustomProfileContentProps {
	theme: AllThemes;
	url: string;
	mediaUrl: string;
	description: string;
	isPreview?: boolean;
	totalPosts?: number;
	imageSize: CustomContentImageSizeType;
}

export const CustomProfileContent: React.FC<CustomProfileContentProps> = ({
	mediaUrl,
	url,
	imageSize,
	description,
}) => {
	const width = () => {
		if (imageSize === CustomContentImageSize.Enum.horizontal) {
			return "w-[711px] min-w-[711px] lg:w-[711px] lg:min-w-[711px]";
		} else if (imageSize === CustomContentImageSize.Enum.vertical) {
			return "lg:w-[225px] lg:min-w-[225px] w-[225px] min-w-[225px] ";
		} else {
			return "lg:w-[400px] lg:min-w-[400px] w-[400px] min-w-[400px]";
		}
	};

	return (
		<a href={url} target="_blank">
			<div
				className={cx(
					"group relative flex aspect-square h-[400px] flex-col justify-end overflow-hidden rounded-2xl bg-cover lg:h-[400px]",
					width(),
				)}
			>
				<div
					className={cx(
						"absolute flex aspect-square h-[400px] flex-col justify-end overflow-hidden rounded-b-3xl rounded-t-2xl bg-cover transition-all group-hover:scale-105 lg:h-[400px] ",
						width(),
					)}
					style={{
						background: `url(${mediaUrl})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
					}}
				></div>

				<div
					className={cx(
						"absolute z-10 h-full w-full bg-gradient-to-t from-gray-900/20 to-transparent transition-all group-hover:opacity-0 dark:from-gray-900/80",
					)}
				></div>
				{description && description !== "" ? (
					<div
						onClick={(e) => {
							e.preventDefault();
						}}
						className={cx(
							"z-20 flex w-full flex-row items-center justify-between rounded-b-2xl bg-black/10 p-4 backdrop-blur-md",
						)}
					>
						<p className="text-paragraph-md font-repro">{description}</p>
					</div>
				) : null}
			</div>
		</a>
	);
};
