import { z } from "zod";

export const DealStatusSchema = z.enum([
	"negotiating",
	"delivering",
	"invoiced",
	"paid",
	"overdue",
	"canceled",
]);
export type DealStatus = z.infer<typeof DealStatusSchema>;

export const DealTaskStatusSchema = z.enum(["complete", "incomplete"]);
export type DealTaskStatus = z.infer<typeof DealTaskStatusSchema>;

export const DealDeliverableSchema = z.object({
	title: z.string().min(2),
	amount: z.number(),
});

export type DealDeliverable = z.infer<typeof DealDeliverableSchema>;

export const DealDeliverablesSchema = z.array(DealDeliverableSchema);
export type DealDeliverables = z.infer<typeof DealDeliverablesSchema>;

export const DealEntrySchema = z.object({
	uuid: z.string().uuid(),
	talent: z
		.object({
			uuid: z.string().uuid(),
			displayName: z.string(),
			profilePictureUrl: z.string().optional(),
		})
		.optional(),
	deliverables: DealDeliverablesSchema,
});
export type DealEntry = z.infer<typeof DealEntrySchema>;

export const DealTaskSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string().min(1),
	status: DealTaskStatusSchema,
	dueDate: z.coerce.date().optional(),
});
export type DealTask = z.infer<typeof DealTaskSchema>;

export const DealTasksSchema = z.array(DealTaskSchema);
export type DealTasks = z.infer<typeof DealTasksSchema>;

export const AgencyDealSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string().min(1),
	brandName: z.string(),
	entries: z.array(DealEntrySchema),
	status: DealStatusSchema,
	tasks: z.array(DealTaskSchema),
	notes: z.string().optional(),
});

export type AgencyDeal = z.infer<typeof AgencyDealSchema>;

export const AgencyDealPreviewSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string().min(1),
	brandName: z.string(),
	status: DealStatusSchema,
	creators: z.string(),
	nextDueDate: z.coerce.date().optional(),
	deliverables: z.object({
		count: z.number(),
		amount: z.number(),
	}),
	allTasksCompleted: z.boolean(),
	order: z.number(),
});

export type AgencyDealPreview = z.infer<typeof AgencyDealPreviewSchema>;
