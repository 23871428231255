import * as Tabs from "@radix-ui/react-tabs";
import {
	CustomProfile,
	MediaKitCollabsBlock,
	MediaKitFacebookBlock,
	MediaKitInstagramBlock,
	MediaKitRatesBlock,
	MediaKitTikTokBlock,
	MediaKitTwitchBlock,
	MediaKit as MediaKitType,
	MediaKitYouTubeBlock,
	isLightTiktokLogo,
	nonNull,
} from "@withjuly/fabric";
import {
	cx,
	getBackgroundFillColor,
	getThemeStyles,
} from "@withjuly/frontend-common";
import {
	InstagramLogo,
	FacebookLogo,
	JulyLogo,
	TiktokDarkLogo,
	TiktokLogo,
	TwitchLogo,
	YoutubeLogo,
} from "@withjuly/julycons";
import { Button } from "@withjuly/solis";
import { useRouter } from "next/router";
import { useState } from "react";
import { getBlocksOfType } from "~/utils/mediakits";
import { FacebookStats } from "./FacebookStats";
import { Header } from "./Header";
import { InstagramStats } from "./InstagramStats";
import { Partnerships } from "./Partnerships";
import { Rates } from "./Rates";
import { TikTokStats } from "./TikTokStats";
import { TwitchStats } from "./TwitchStats";
import { YouTubeStats } from "./YouTubeStats";
import { CustomProfileStats } from "./CustomProfileStats";
import Image from "next/image";
import { getBioDomain, getFrontendDomain } from "~/utils/urls";

export const MediaKit: React.FC<{
	mediaKit: MediaKitType;
	username?: string;
	isPreview?: boolean;
	isMobilePreview?: boolean;
	hideNav?: boolean;
	isEmbed?: boolean;
}> = ({ mediaKit, username, isPreview, isMobilePreview, isEmbed }) => {
	const router = useRouter();
	const [hasCopied, setHasCopied] = useState<boolean>(false);
	const {
		blocks: instagramBlocks,
		enabled: hasEnabledInstagramBlock,
		lowestOrder: lowestOrderInstagramBlock,
	} = getBlocksOfType<MediaKitInstagramBlock>(mediaKit.blocks, "instagram");
	const {
		blocks: tiktokBlocks,
		enabled: hasEnabledTiktokBlock,
		lowestOrder: lowestOrderTiktokBlock,
	} = getBlocksOfType<MediaKitTikTokBlock>(mediaKit.blocks, "tiktok");
	const {
		blocks: youtubeBlocks,
		enabled: hasEnabledYoutubeBlock,
		lowestOrder: lowestOrderYoutubeBlock,
	} = getBlocksOfType<MediaKitYouTubeBlock>(mediaKit.blocks, "youtube");
	const {
		blocks: facebookBlocks,
		enabled: hasEnabledFacebookBlock,
		lowestOrder: lowestOrderFacebookBlock,
	} = getBlocksOfType<MediaKitFacebookBlock>(mediaKit.blocks, "facebook");
	const {
		blocks: twitchBlocks,
		enabled: hasEnabledTwitchBlock,
		lowestOrder: lowestOrderTwitchBlock,
	} = getBlocksOfType<MediaKitTwitchBlock>(mediaKit.blocks, "twitch");

	const ratesBlock = mediaKit.blocks.find((block) => block.type === "rates") as
		| MediaKitRatesBlock
		| undefined;
	const collabsBlock = mediaKit.blocks.find(
		(block) => block.type === "collabs",
	) as MediaKitCollabsBlock | undefined;

	const hasCustomProfiles = mediaKit.customProfiles.length !== 0;
	const customProfiles: CustomProfile[] = hasCustomProfiles
		? mediaKit.customProfiles.filter((p) => p.isActive)
		: [];

	const platformTabs = [
		{
			type: "instagram",
			enabled: hasEnabledInstagramBlock,
			order: lowestOrderInstagramBlock,
		},
		{
			type: "tiktok",
			enabled: hasEnabledTiktokBlock,
			order: lowestOrderTiktokBlock,
		},
		{
			type: "youtube",
			enabled: hasEnabledYoutubeBlock,
			order: lowestOrderYoutubeBlock,
		},
		{
			type: "twitch",
			enabled: hasEnabledTwitchBlock,
			order: lowestOrderTwitchBlock,
		},
		{
			type: "facebook",
			enabled: hasEnabledFacebookBlock,
			order: lowestOrderFacebookBlock,
		},
	]
		.filter(nonNull)
		.filter((tab) => tab && tab.enabled)
		.sort((a, b) => a.order - b.order);
	const getDefaultTab = () => {
		const platformTab = platformTabs[0];
		if (platformTab) {
			return platformTab.type;
		} else {
			if (ratesBlock && ratesBlock.enabled) {
				return "rates";
			}

			return "collabs";
		}
	};
	const [currentTab, setCurrentTab] = useState<string>(
		(router.query.tab as string | undefined) ?? getDefaultTab(),
	);

	return (
		<>
			<style jsx global>
				{`
					${getThemeStyles(
						mediaKit.theme,
						mediaKit.darkMode ? "dark" : "light",
						mediaKit.customTheme,
					)}
				`}
			</style>
			<div
				className={cx(
					"bg-theme-background relative z-20 flex min-h-screen w-full flex-col items-center justify-start",
				)}
			>
				{mediaKit.agencyName !== "" ? (
					<div
						className={cx(
							"bg-theme-background border-b-theme-stroke sticky top-0 z-30 flex w-full items-center justify-center gap-3 border-b p-4",
							isPreview && "hidden",
						)}
					>
						<p className="text-theme-text-primary hidden md:block">
							Represented by
						</p>
						{mediaKit.agencyLogo ? (
							<Image
								src={mediaKit.agencyLogo}
								width={32}
								height={32}
								alt={`${mediaKit.agencyName}'s logo`}
								className="block h-8 w-8 rounded-full object-cover object-center md:hidden lg:hidden"
							/>
						) : null}
						<a
							href={`mailto:${mediaKit.contact}`}
							className="block w-full md:hidden lg:hidden"
						>
							<Button
								className="bg-theme-button-fill hover:bg-theme-button-fill/90 text-theme-text-button block w-full max-w-full truncate hover:shadow-lg"
								size="large"
							>
								<div className="max-w-64 truncate sm:max-w-full md:max-w-full">
									Contact {mediaKit.agencyName}
								</div>
							</Button>
						</a>
						<a
							className="hidden items-center gap-3 md:flex"
							target="_blank"
							href={`https://${getBioDomain()}/${mediaKit.agencyUsername}`}
						>
							{mediaKit.agencyLogo ? (
								<Image
									src={mediaKit.agencyLogo}
									width={32}
									height={32}
									alt={`${mediaKit.agencyName}'s logo`}
									className="h-8 w-8 rounded-full object-cover object-center"
								/>
							) : null}

							<p className="text-theme-text-primary font-semibold">
								{mediaKit.agencyName}
							</p>
						</a>
					</div>
				) : null}
				<div className="bg-theme-background flex w-full flex-col items-center justify-start gap-12 px-6 pb-16 pt-16 md:px-8 md:pt-[104px] lg:pt-[104px]">
					<div className="flex w-full flex-col items-center justify-center gap-8 md:gap-4">
						<Header
							mediaKit={mediaKit}
							isPreview={isPreview}
							isMobilePreview={isMobilePreview}
						/>
					</div>
					<div className="h-full w-full max-w-[1200px]">
						<Tabs.Root
							value={currentTab}
							onValueChange={(tab) => {
								setCurrentTab(tab);
							}}
							className="flex h-full w-full flex-col gap-12 md:gap-8"
						>
							<Tabs.List
								aria-label="Platform posts and stats"
								className={cx(
									"bg-theme-fill border-theme-stroke flex w-full items-center gap-2 overflow-x-scroll rounded-xl border p-2 md:rounded-2xl",
									isPreview && !isMobilePreview && "lg:rounded-2xl",
								)}
							>
								{platformTabs
									.sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0))
									.map((block, i) => {
										if (!block) {
											return null;
										}
										return (
											<PlatformTabs
												key={`platform-tab-${i}`}
												type={block.type}
												isMobilePreview={isMobilePreview}
												isEmbed={isEmbed}
												backgroundColor={getBackgroundFillColor(
													mediaKit.theme,
													mediaKit.darkMode ? "dark" : "light",
													mediaKit.customTheme,
												)}
											/>
										);
									})}
								{/* CUSTOM PROFILES */}
								{hasCustomProfiles
									? customProfiles.map((profile, index) => (
											<SectionTab
												key={profile.uuid}
												value={`${profile.title.toLowerCase()}-${index}`}
												title={profile.title}
												isEmbed={isEmbed}
											/>
										))
									: null}
								{/* RATES */}
								{ratesBlock &&
								ratesBlock.enabled &&
								ratesBlock.rates.length > 0 ? (
									<SectionTab value="rates" title="Rates" isEmbed={isEmbed} />
								) : null}
								{/* PARTNERSHIPS */}
								{collabsBlock &&
								collabsBlock.enabled &&
								collabsBlock.collabs.length > 0 ? (
									<SectionTab
										value="collabs"
										title="Partnerships"
										isEmbed={isEmbed}
									/>
								) : null}
							</Tabs.List>

							{hasEnabledInstagramBlock ? (
								<Tabs.Content value="instagram">
									<InstagramStats
										blocks={instagramBlocks.filter(
											(block) => block.enabled === true,
										)}
										theme={mediaKit.theme}
										isPreview={isPreview}
										isMobilePreview={isMobilePreview}
										darkMode={mediaKit.darkMode}
										showDateRangeFilters={mediaKit.showDateRangeFilters}
									/>
								</Tabs.Content>
							) : null}

							{hasEnabledTiktokBlock ? (
								<Tabs.Content value="tiktok">
									<TikTokStats
										blocks={tiktokBlocks.filter(
											(block) => block.enabled === true,
										)}
										theme={mediaKit.theme}
										isPreview={isPreview}
										isMobilePreview={isMobilePreview}
										darkMode={mediaKit.darkMode}
										showDateRangeFilters={mediaKit.showDateRangeFilters}
									/>
								</Tabs.Content>
							) : null}

							{hasEnabledYoutubeBlock ? (
								<Tabs.Content value="youtube">
									<YouTubeStats
										blocks={youtubeBlocks.filter(
											(block) => block.enabled === true,
										)}
										theme={mediaKit.theme}
										isPreview={isPreview}
										isMobilePreview={isMobilePreview}
										darkMode={mediaKit.darkMode}
										showDateRangeFilters={mediaKit.showDateRangeFilters}
									/>
								</Tabs.Content>
							) : null}

							{hasEnabledFacebookBlock ? (
								<Tabs.Content value="facebook">
									<FacebookStats
										blocks={facebookBlocks}
										theme={mediaKit.theme}
										isPreview={isPreview}
										isMobilePreview={isMobilePreview}
										darkMode={mediaKit.darkMode}
										showDateRangeFilters={mediaKit.showDateRangeFilters}
									/>
								</Tabs.Content>
							) : null}

							{hasEnabledTwitchBlock ? (
								<Tabs.Content value="twitch">
									<TwitchStats
										blocks={twitchBlocks}
										theme={mediaKit.theme}
										isPreview={isPreview}
										isMobilePreview={isMobilePreview}
										darkMode={mediaKit.darkMode}
									/>
								</Tabs.Content>
							) : null}

							{hasCustomProfiles
								? customProfiles.map((profile, index) => (
										<Tabs.Content
											value={`${profile.title.toLowerCase()}-${index}`}
											key={profile.uuid}
										>
											<CustomProfileStats
												profile={profile}
												theme={mediaKit.theme}
												isPreview={isPreview}
												isMobilePreview={isMobilePreview}
												darkMode={mediaKit.darkMode}
											/>
										</Tabs.Content>
									))
								: null}

							{ratesBlock?.enabled ? (
								<Tabs.Content value="rates">
									<Rates
										name={mediaKit.name}
										contact={mediaKit.contact}
										block={ratesBlock}
										isPreview={isPreview}
										isMobilePreview={isMobilePreview}
										theme={mediaKit.theme}
										currency={mediaKit.currency}
									/>
								</Tabs.Content>
							) : null}

							{collabsBlock?.enabled ? (
								<Tabs.Content value="collabs">
									<Partnerships
										mediaKit={mediaKit}
										block={collabsBlock}
										isPreview={isPreview}
										isMobilePreview={isMobilePreview}
										theme={mediaKit.theme}
									/>
								</Tabs.Content>
							) : null}
						</Tabs.Root>
					</div>
				</div>
				<div className="bg-theme-background flex w-full flex-col items-center justify-start gap-12 pb-16">
					<div className="flex w-full flex-col items-center justify-center">
						<div className="flex w-full max-w-[1200px] flex-col items-center justify-center gap-6 rounded-2xl p-8">
							{mediaKit.agencyName !== "" ? (
								<div className="hidden items-center gap-3 md:flex lg:flex">
									<p className="text-theme-text-primary hidden md:block">
										Represented by
									</p>
									<a
										target="_blank"
										href={`https://${getBioDomain()}/${mediaKit.agencyUsername}`}
										className="flex items-center gap-3"
									>
										{mediaKit.agencyLogo ? (
											<Image
												src={mediaKit.agencyLogo}
												width={32}
												height={32}
												alt={`${mediaKit.agencyName}'s logo`}
												className="h-8 w-8 rounded-full object-cover object-center"
											/>
										) : null}
										<p className="text-theme-text-primary hidden font-semibold md:block">
											{mediaKit.agencyName}
										</p>
									</a>
								</div>
							) : null}
							<a
								href={`mailto:${mediaKit.contact}`}
								onClick={() => {
									navigator.clipboard.writeText(mediaKit.contact).then(() => {
										setHasCopied(true);
										setTimeout(() => {
											setHasCopied(false);
										}, 1000);
									});
								}}
							>
								<Button
									className="bg-theme-button-fill text-theme-text-button hover:bg-theme-button-fill/80 active:bg-theme-button-fill hover:shadow-lg"
									variant="primary"
									size="huge"
								>
									{hasCopied
										? "Email copied"
										: `Contact
									${mediaKit.agencyName === "" ? mediaKit.name : mediaKit.agencyName}`}
								</Button>
							</a>
						</div>

						<div className="bg-theme-background border-theme-stroke mt-8 flex w-full flex-col items-center gap-2 border-t pt-16">
							{username ? (
								<a
									href={`https://${getFrontendDomain()}/agency/mediakits/pdf?username=${username}`}
								>
									<Button
										className="bg-theme-button-fill text-theme-text-button hover:bg-theme-button-fill/80 active:bg-theme-button-fill mb-8 hover:shadow-lg"
										variant="primary"
										size="huge"
									>
										Download as PDF
									</Button>
								</a>
							) : null}

							<a href="https://withjuly.com" target="_blank">
								<p className="text-button-sm text-theme-text-primary flex items-center gap-1">
									Powered by{" "}
									<JulyLogo
										className={cx("text-theme-text-primary h-4 w-10")}
									/>
								</p>
							</a>
							<p className="text-paragraph-xs text-theme-text-secondary">
								Logos provided by{" "}
								<a href="https://clearbit.com" target="_blank">
									Clearbit
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

interface SectionTabProps {
	title: string;
	value: string;
	icon?: React.ReactElement;
	isEmbed?: boolean;
}

const SectionTab: React.FC<SectionTabProps> = ({
	title,
	value,
	icon,
	isEmbed,
}) => {
	const router = useRouter();

	return (
		<Tabs.Trigger
			value={value}
			className="bg-trasparent itmes-center data-[state=active]:bg-theme-button-fill group flex w-full items-center justify-center gap-3 whitespace-nowrap rounded-xl px-6 py-3 transition-all"
			onClick={() => {
				if (!isEmbed) {
					router.replace({
						query: {
							...router.query,
							tab: value,
						},
					});
				}
			}}
		>
			{icon ?? null}
			<p className="group-data-[state=active]:text-theme-text-button text-theme-text-primary whitespace-nowrap">
				{title}
			</p>
		</Tabs.Trigger>
	);
};

interface PlatformTabsProps {
	type: string;
	isMobilePreview?: boolean;
	isEmbed?: boolean;
	backgroundColor: string;
}

const PlatformTabs: React.FC<PlatformTabsProps> = ({
	type,
	isEmbed,
	backgroundColor,
}) => {
	let Logo = InstagramLogo;
	let tabTitle = "Instagram";
	if (type === "youtube") {
		Logo = YoutubeLogo;
		tabTitle = "YouTube";
	} else if (type === "tiktok") {
		Logo = isLightTiktokLogo(backgroundColor) ? TiktokLogo : TiktokDarkLogo;
		tabTitle = "TikTok";
	} else if (type === "twitch") {
		Logo = TwitchLogo;
		tabTitle = "Twitch";
	} else if (type === "facebook") {
		Logo = FacebookLogo;
		tabTitle = "Facebook";
	}

	return (
		<SectionTab
			title={tabTitle}
			value={type}
			icon={<Logo className="h-5 w-5" />}
			isEmbed={isEmbed}
		/>
	);
};
