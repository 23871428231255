import React, { ReactNode, useId } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormInputWrapper } from "@withjuly/solis";
import { ToggleSwitch } from "@withjuly/solisv2";

export interface SwitchInputProps {
	name: string;
	label?: ReactNode;
	disabled?: boolean;
	onChange?: () => void;
	className?: string;
}

export const Switch: React.FC<SwitchInputProps> = ({
	name,
	label,
	disabled,
	onChange,
	className,
}) => {
	const { control } = useFormContext();
	const id = useId();

	return (
		<FormInputWrapper name={name} id={id}>
			<Controller
				control={control}
				name={name}
				render={({ field }) => (
					<ToggleSwitch
						id={name}
						label={label}
						toggle={field.value}
						onToggle={(checked) => {
							field.onChange(checked);
							onChange?.();
						}}
						disabled={disabled}
						className={className}
						{...field}
					/>
				)}
			/>
		</FormInputWrapper>
	);
};
