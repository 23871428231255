import * as Dialog from "@radix-ui/react-dialog";
import {
	AllThemes,
	MediaKit,
	MediaKitCollab,
	MediaKitCollabsBlock,
} from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import { Chart, Close, ExternalLink } from "@withjuly/julycons";
import { Button } from "@withjuly/solis";
import { useState } from "react";

interface PartnershipsProps {
	mediaKit: MediaKit;
	block: MediaKitCollabsBlock;
	theme: AllThemes;
	isPreview?: boolean;
	isMobilePreview?: boolean;
}

export const Partnerships: React.FC<PartnershipsProps> = ({
	mediaKit,
	block,
	theme,
	isPreview,
	isMobilePreview,
}) => {
	return (
		<div>
			<div className="flex items-end gap-3">
				<p className="text-theme-text-primary text-xl font-semibold">
					Past Partnerships
				</p>
				<p className="text-body-sm text-theme-text-secondary flex items-center gap-1 pb-[3px] opacity-50">
					Click to view details
				</p>
			</div>
			<div className="animate-open-fade-move-up mt-8 flex flex-wrap items-start gap-y-8">
				{block.collabs
					.sort((a, b) => a.order - b.order)
					.map((collab, i) => {
						return (
							<Partnership
								key={`collab-${i}`}
								mediaKit={mediaKit}
								partnership={collab}
								theme={theme}
								isPreview={isPreview}
								isMobilePreview={isMobilePreview}
							/>
						);
					})}
			</div>
		</div>
	);
};

interface PartnershipProps {
	mediaKit: MediaKit;
	partnership: MediaKitCollab;
	theme: AllThemes;
	isPreview?: boolean;
	isMobilePreview?: boolean;
}

const Partnership: React.FC<PartnershipProps> = ({
	mediaKit,
	partnership,
	theme,
	isPreview,
	isMobilePreview,
}) => {
	const [partnershipModalOpen, setPartnershipModalOpen] = useState(false);

	const hasIndepthData =
		(partnership.description && partnership.description !== "") ||
		(partnership.testimonial && partnership.testimonial !== "") ||
		partnership.stats.length !== 0;

	if (!hasIndepthData) {
		return (
			<a
				href={partnership.url}
				target="_blank"
				className={cx(
					"group flex w-full basis-1/2 flex-col items-center justify-center gap-3 md:basis-1/4",
					isPreview && !isMobilePreview && "lg:basis-[1/4]",
					isMobilePreview && "basis-1/2 md:basis-1/2",
					!isPreview && "lg:basis-[14%]",
				)}
			>
				<div className="bg-theme-button flex h-24 w-24 items-center justify-center overflow-clip rounded-full bg-opacity-5">
					{partnership.logoUrl ? (
						<img
							src={partnership.logoUrl}
							alt=""
							className="h-full w-full object-cover object-center transition-all group-hover:scale-105"
						/>
					) : (
						<div className="text-theme-text-button text-2xl font-bold uppercase">
							{partnership.title[0]}
						</div>
					)}
				</div>
				<p className="text-body-xl text-theme-text-primary text-center capitalize leading-normal">
					{partnership.title}
				</p>
			</a>
		);
	}

	return (
		<>
			<button
				className={cx(
					"group flex w-full basis-1/2 flex-col items-center justify-center gap-3 md:basis-1/4",
					isPreview && !isMobilePreview && "lg:basis-[1/4]",
					isMobilePreview && "basis-1/2 md:basis-1/2",
					!isPreview && "lg:basis-[14%]",
				)}
				onClick={() => setPartnershipModalOpen(() => true)}
			>
				<div className="bg-theme-button-fill relative flex h-24 w-24 items-center justify-center rounded-full">
					{partnership.logoUrl ? (
						<img
							src={partnership.logoUrl}
							alt=""
							className="h-full w-full rounded-full object-cover object-center transition-all group-hover:scale-105"
						/>
					) : (
						<div className="text-theme-text-button text-2xl font-bold uppercase">
							{partnership.title[0]}
						</div>
					)}
					<div className="bg-theme-button-fill-secondary text-theme-text-primary absolute bottom-0 right-0 rounded-full p-2">
						<Chart />
					</div>
				</div>
				<p className="text-body-xl text-theme-text-primary capitalize">
					{partnership.title}
				</p>
			</button>

			<PartnershipModal
				mediaKit={mediaKit}
				partnership={partnership}
				theme={theme}
				setIsOpen={setPartnershipModalOpen}
				isOpen={partnershipModalOpen}
			/>
		</>
	);
};

interface PartnershipModalProps {
	mediaKit: MediaKit;
	partnership: MediaKitCollab;
	theme: AllThemes;
	setIsOpen: (isOpen: boolean) => void;
	isOpen: boolean;
}

const PartnershipModal: React.FC<PartnershipModalProps> = ({
	mediaKit,
	partnership,
	setIsOpen,
	isOpen,
}) => {
	return (
		<Dialog.Root open={isOpen} onOpenChange={setIsOpen} modal>
			<Dialog.Portal>
				<Dialog.Overlay
					className="fixed inset-0 z-30 bg-black/80"
					onClick={() => setIsOpen(false)}
				/>
				<Dialog.Content
					className={cx(
						"shadow-100 bg-theme-background fixed left-1/2 top-1/2 z-40 h-screen max-h-screen w-full -translate-x-1/2 -translate-y-1/2 transform overflow-y-scroll p-8 md:h-auto md:max-h-[90%] md:w-[560px] md:rounded-2xl dark:bg-gray-700",
					)}
				>
					<div className="relative flex flex-col items-center justify-center gap-6">
						<Dialog.Close
							className="text-theme-text-primary absolute right-0 top-0"
							onClick={() => setIsOpen(false)}
						>
							<Close />
						</Dialog.Close>
						<div className="bg-theme-button-fill flex h-20 w-20 items-center justify-center self-start overflow-clip rounded-full">
							{partnership.logoUrl ? (
								<img
									src={partnership.logoUrl}
									alt=""
									className="h-full w-full object-cover object-center"
								/>
							) : (
								<div className="text-theme-text-button text-2xl font-bold uppercase">
									{partnership.title[0]}
								</div>
							)}
						</div>
						<div className="flex w-full flex-col items-start gap-6">
							<p className="text-theme-text-primary text-[20px] font-semibold leading-[1.30em]">
								{mediaKit.name} x {partnership.title}
							</p>
							<div
								className={cx(
									"flex flex-col gap-3",
									(!partnership.description ||
										partnership.description === "") &&
										"hidden",
								)}
							>
								<p className="text-bold-xl text-theme-text-primary">Overview</p>
								<p className="text-theme-text-primary">
									{partnership.description ?? ""}
								</p>
							</div>
							<div className="grid w-full grid-cols-1 gap-6 overflow-y-scroll md:grid-cols-2">
								{partnership.stats.map((stat, index) => (
									<div key={index} className={cx("w-full")}>
										<div className="bg-theme-fill border-theme-stroke flex w-full flex-col gap-1 rounded-xl border p-4">
											<p className="text-body-lg text-theme-text-secondary">
												{stat.title}
											</p>
											<p className="text-theme-text-primary text-[24px] leading-[1.30em]">
												{stat.value}
											</p>
										</div>
									</div>
								))}
							</div>
							<div
								className={cx(
									"bg-theme-fill border-theme-stroke flex w-full flex-col gap-2 rounded-xl border p-4",
									(!partnership.testimonial ||
										partnership.testimonial === "") &&
										"hidden",
								)}
							>
								<p className="text-theme-text-secondary text-bold-xl">
									Brand Testimonial
								</p>
								<p className="text-theme-text-primary">
									{partnership.testimonial}
								</p>
							</div>
							<a
								href={partnership.url}
								target="_blank"
								rel="noreferrer"
								className="text-body-lg flex w-full items-center gap-1"
							>
								<Button
									className="bg-theme-button-fill divide-theme-text-button hover:bg-theme-button-fill/80 w-full hover:shadow-lg"
									size="huge"
								>
									<div className="text-theme-text-button flex w-full flex-row items-center justify-center gap-2">
										View Post <ExternalLink className="mb-[3px] h-4 w-4" />
									</div>
								</Button>
							</a>
						</div>
					</div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};
