import {
	AllThemes,
	MediaKitStat,
	MediaKitValueStat,
	MediaKitYouTubeBlock,
	formatFollowerCount,
} from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import {
	Chart,
	Chat,
	ExternalLink,
	Forward,
	HeartFull,
	JulyLogo,
	Play,
	User,
	YoutubeLogo,
} from "@withjuly/julycons";
import { BarStat } from "./BarStat";
import { PieStat } from "./PieStat";
import { PlatformContent } from "./PlatformContent";
import { StatCard } from "./StatCard";
import { useEffect, useMemo, useState } from "react";
import { getEngagementRateThresholdValue } from "~/utils/mediakits";
import { Eye, RocketLaunch } from "@withjuly/julycons/bold";
import { TimeBetween } from "~/components/Utility/Time/TimeBetween";
import { DateRange } from "./InstagramStats";

interface YouTubeStatsProps {
	blocks: MediaKitYouTubeBlock[];
	theme: AllThemes;
	isPreview?: boolean;
	isMobilePreview?: boolean;
	darkMode: boolean;
	showDateRangeFilters: boolean;
}

export const YouTubeStats: React.FC<YouTubeStatsProps> = ({
	blocks,
	theme,
	isPreview,
	isMobilePreview,
	darkMode,
	showDateRangeFilters,
}) => {
	blocks.sort((a, b) => {
		const aSubs =
			(
				a.fields.stats.find((stat) => stat.name === "subscribers") as
					| MediaKitValueStat
					| undefined
			)?.value ?? 0;

		const bSubs =
			(
				b.fields.stats.find((stat) => stat.name === "subscribers") as
					| MediaKitValueStat
					| undefined
			)?.value ?? 0;

		return bSubs - aSubs;
	});

	return (
		<div className="flex flex-col gap-32">
			{blocks.map((block, i) => (
				<OneYouTubeStats
					key={`youtube-stats-${i}`}
					indexText={
						blocks.length > 1
							? `${i + 1} of ${blocks.length} channels`
							: undefined
					}
					block={block}
					theme={theme}
					isPreview={isPreview}
					isMobilePreview={isMobilePreview}
					darkMode={darkMode}
					showDateRangeFilters={showDateRangeFilters}
				/>
			))}
		</div>
	);
};

interface OneYouTubeStatsProps {
	block: MediaKitYouTubeBlock;
	indexText?: string;
	theme: AllThemes;
	isPreview?: boolean;
	isMobilePreview?: boolean;
	darkMode: boolean;
	showDateRangeFilters: boolean;
}

export const OneYouTubeStats: React.FC<OneYouTubeStatsProps> = ({
	block,
	indexText,
	theme,
	isPreview,
	isMobilePreview,
	showDateRangeFilters,
}) => {
	const [selectedRange, setSelectedRange] = useState<DateRange>(
		DateRange.month,
	);
	const displayStats = useMemo(() => {
		if (!block.areTabsVisible || !block.isAuth) {
			return block.fields.stats;
		} else if (selectedRange === DateRange.week) {
			return block.fields.weekStats ?? [];
		} else if (selectedRange === DateRange.twoWeek) {
			return block.fields.twoWeekStats ?? [];
		} else if (selectedRange === DateRange.month) {
			return block.fields.monthStats ?? [];
		}
		return block.fields.stats;
	}, [
		block.areTabsVisible,
		block.fields.monthStats,
		block.fields.stats,
		block.fields.twoWeekStats,
		block.fields.weekStats,
		block.isAuth,
		selectedRange,
	]);

	const displayStatsGroup1 = useMemo(
		() =>
			displayStats.filter(
				(stat) =>
					["subscribers", "engagement_rate", "total_views"].includes(
						stat.name,
					) && stat.enabled,
			),
		[displayStats],
	);

	const displayStatsGroup2 = useMemo(
		() =>
			displayStats.filter(
				(stat) =>
					[
						"average_likes",
						"average_views",
						"average_comments",
						"average_shares",
						"average_watch_time",
					].includes(stat.name) && stat.enabled,
			),
		[displayStats],
	);

	const displayStatsGroup3 = useMemo(
		() =>
			displayStats.filter(
				(stat) =>
					["average_shorts_likes", "average_shorts_views"].includes(
						stat.name,
					) && stat.enabled,
			),
		[displayStats],
	);

	const customStats = useMemo(
		() =>
			block.fields.customStats?.filter(
				(stat) => stat.enabled && stat.type === "custom",
			),
		[block.fields.customStats],
	);

	useEffect(() => {
		if (showDateRangeFilters === false) {
			setSelectedRange(DateRange.month);
		}
	}, [showDateRangeFilters]);

	const ageStat = displayStats.find((stat) => stat.name === "age") as
		| (MediaKitStat & { type: "bar" })
		| undefined;
	const countryStat = displayStats.find((stat) => stat.name === "country") as
		| (MediaKitStat & { type: "bar" })
		| undefined;
	if (countryStat) {
		const sortedByValue = Object.entries(countryStat.data).sort(
			(a, b) => b[1] - a[1],
		);

		const toDisplay = sortedByValue.slice(0, 3);
		if (sortedByValue.length > 3) {
			const otherSum = sortedByValue
				.slice(3)
				.map(([, value]) => value)
				.reduce((a, b) => a + b, 0);

			toDisplay.push(["Other", otherSum]);
		}
	}
	const genderStat = displayStats.find((stat) => stat.name === "gender") as
		| (MediaKitStat & { type: "pie" })
		| undefined;
	const engagementRateStat = displayStats.find(
		(s) => s.name === "engagement_rate",
	) as (MediaKitStat & { type: "value" }) | undefined;
	const followersStat = displayStats.find((s) => s.name === "followers") as
		| (MediaKitStat & { type: "value" })
		| undefined;
	const formattedRate = useMemo(() => {
		let value = formatFollowerCount(Math.round(engagementRateStat?.value ?? 0));
		if (engagementRateStat?.display === "percent") {
			value = (engagementRateStat?.value / 100).toLocaleString("en-US", {
				style: "percent",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
		}
		return value;
	}, [engagementRateStat]);

	const aboveThreshold = useMemo(() => {
		const thresholdValue = getEngagementRateThresholdValue(
			followersStat?.value ?? 0,
			"youtube",
		);
		return thresholdValue;
	}, [followersStat]);

	const isShortsVisible = useMemo(() => {
		const stat = displayStats.find(
			(s) =>
				s.name === "average_shorts_views" || s.name === "average_shorts_likes",
		);
		if (stat?.type === "value") {
			return stat.value !== undefined && stat.value !== 0;
		} else {
			return false;
		}
	}, [displayStats]);

	const updatedAt = displayStats.reduce((acc, stat) => {
		if (stat.type === "value") {
			acc.push(stat);
		}
		return acc;
	}, [] as MediaKitValueStat[])[0]?.updatedAt;

	const dateRangeTabs = (isMobile: boolean) => {
		return (
			<div className="flex w-full gap-2">
				<button
					onClick={() => setSelectedRange(DateRange.twoMonth)}
					className={cx(
						"text-body-xl flex h-7 items-center justify-center rounded-xl p-[10px]",
						selectedRange === DateRange.twoMonth
							? "bg-theme-button-fill text-theme-text-button"
							: "bg-theme-button-fill-secondary text-theme-text-primary",
						isMobile && "w-full",
					)}
				>
					{isMobile ? "" : "Past"} 60d
				</button>
				<button
					onClick={() => setSelectedRange(DateRange.month)}
					className={cx(
						"text-body-xl flex h-7 items-center justify-center rounded-xl p-[10px]",
						selectedRange === DateRange.month
							? "bg-theme-button-fill text-theme-text-button"
							: "bg-theme-button-fill-secondary text-theme-text-primary",
						isMobile && "w-full",
					)}
				>
					{isMobile ? "" : "Past"} 30d
				</button>
				<button
					onClick={() => setSelectedRange(DateRange.twoWeek)}
					className={cx(
						"text-body-xl flex h-7 items-center justify-center rounded-xl p-[10px]",
						selectedRange === "14d"
							? "bg-theme-button-fill text-theme-text-button"
							: "bg-theme-button-fill-secondary text-theme-text-primary",
						isMobile && "w-full",
					)}
				>
					{isMobile ? "" : "Past"} 14d
				</button>
				<button
					onClick={() => setSelectedRange(DateRange.week)}
					className={cx(
						"text-body-xl flex h-7 items-center justify-center rounded-xl p-[10px]",
						selectedRange === "7d"
							? "bg-theme-button-fill text-theme-text-button"
							: "bg-theme-button-fill-secondary text-theme-text-primary",
						isMobile && "w-full",
					)}
				>
					{isMobile ? "" : "Past"} 7d
				</button>
			</div>
		);
	};

	if (!block.enabled) {
		return null;
	}

	return (
		<div
			className={cx(
				"animate-open-fade-move-down flex h-full w-full flex-col gap-16",
				isPreview && !isMobilePreview && "min-w-[680px]",
			)}
		>
			<div className="flex flex-col gap-4">
				<div className="flex items-end justify-between gap-3 ">
					<a
						href={block.fields.accountUrl}
						target="_blank"
						className="flex items-center gap-4"
					>
						<YoutubeLogo className="text-white-100 h-8 w-8" />
						<div className="flex flex-col">
							<p className="text-theme-text-primary text-xl font-semibold">
								{block.fields.username}
							</p>
							{indexText ? (
								<p className="text-theme-text-secondary">{indexText}</p>
							) : null}
						</div>
					</a>

					<a
						href={block.fields.accountUrl}
						target="_blank"
						rel="noreferrer"
						className="text-theme-text-secondary flex items-center gap-1 pb-[3px] font-medium"
					>
						Go to profile <ExternalLink />
					</a>
				</div>
				<div>
					<PlatformContent block={block} isPreview={isPreview} theme={theme} />
				</div>
			</div>
			<div className="flex h-full w-full flex-col gap-4">
				<div className="mb-2 flex w-full items-center justify-between">
					<div className="flex items-center gap-6">
						<p className="text-theme-text-primary text-xl font-semibold leading-[1.30em]">
							YouTube Stats
						</p>
						{block.isAuth && block.areTabsVisible && showDateRangeFilters ? (
							<div className={cx("hidden lg:flex", isPreview && "lg:hidden")}>
								{dateRangeTabs(false)}
							</div>
						) : null}
					</div>
					{updatedAt ? (
						<div className="flex items-center gap-1.5">
							<p className="text-theme-text-primary text-xs font-semibold leading-[1.30em] opacity-50">
								Updated{" "}
								<TimeBetween
									from={updatedAt}
									isMobilePreview={isMobilePreview}
								/>{" "}
								by
							</p>
							<JulyLogo className="text-theme-text-primary h-4 w-auto" />
						</div>
					) : null}
				</div>
				{block.isAuth && block.areTabsVisible && showDateRangeFilters ? (
					<div className={cx("mb-2 flex lg:hidden", isPreview && "lg:flex")}>
						{dateRangeTabs(true)}
					</div>
				) : null}
				{engagementRateStat?.value &&
				engagementRateStat?.value > aboveThreshold &&
				engagementRateStat?.enabled ? (
					<div className="flex w-full flex-col gap-4 rounded-xl bg-[#C4E6BC] px-8 py-6">
						<div className="font-repro text-body-xl bg-green-6 flex h-[28px] w-fit items-center justify-center gap-1 rounded-full p-[10px]">
							<RocketLaunch />
							{isMobilePreview
								? "High engagement"
								: "Above average performance"}
						</div>
						<p className="text-green-5 font-repro text-body-xl">
							{block.fields.username} has an engagement rate of {formattedRate}.
							This is higher than the average engagement rate{" "}
							{`(${aboveThreshold}%)`} for creators of similar size.
						</p>
					</div>
				) : null}
				<div
					className={cx(
						"flex h-fit flex-wrap items-center gap-y-4 lg:hidden",
						isPreview && !isMobilePreview && "lg:flex",
						isMobilePreview && "hidden",
						displayStatsGroup1.length === 0 && "hidden md:hidden lg:hidden",
					)}
				>
					{displayStatsGroup1.map((stat, i) => {
						if (stat.type !== "value") {
							return null;
						} else {
							let Icon = User;
							if (stat.name === "engagement_rate") {
								Icon = Chart;
							} else if (
								stat.name === "average_likes" ||
								stat.name === "average_shorts_likes"
							) {
								Icon = HeartFull;
							} else if (stat.name === "average_comments") {
								Icon = Chat;
							} else if (
								stat.name === "average_views" ||
								stat.name === "average_shorts_views"
							) {
								Icon = Play;
							} else if (stat.name === "average_shares") {
								Icon = Forward;
							} else if (stat.name === "total_views") {
								Icon = Eye;
							}

							let value = formatFollowerCount(Math.round(stat.value));
							if (stat.display === "percent") {
								value = (stat.value / 100).toLocaleString("en-US", {
									style: "percent",
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								});
							}

							return (
								<div
									key={`youtube-stat-${i}`}
									className={cx(
										"w-full md:basis-1/2 md:gap-4 md:odd:pr-2 md:odd:last:basis-full md:odd:last:p-0 md:even:pl-2",
										isPreview &&
											!isMobilePreview &&
											"lg:basis-full lg:odd:p-0 lg:even:p-0",
										isMobilePreview && "odd:pr-0 even:pl-0",
									)}
								>
									<StatCard
										title={stat.title}
										value={value}
										icon={
											<Icon className="text-theme-text-secondary h-5 w-5" />
										}
										isDetailsEnabled={stat.source !== "custom"}
										stat={stat}
									/>
								</div>
							);
						}
					})}
				</div>
				<div
					className={cx(
						"hidden h-fit items-center gap-4 lg:flex",
						isPreview && !isMobilePreview && "lg:hidden",
						isMobilePreview && "flex flex-col",
						displayStatsGroup1.length === 0 && "hidden md:hidden lg:hidden",
					)}
				>
					{displayStatsGroup1.map((stat, i) => {
						if (stat.type !== "value") {
							return null;
						} else {
							let Icon = User;
							if (stat.name === "engagement_rate") {
								Icon = Chart;
							} else if (
								stat.name === "average_likes" ||
								stat.name === "average_shorts_likes"
							) {
								Icon = HeartFull;
							} else if (stat.name === "average_comments") {
								Icon = Chat;
							} else if (
								stat.name === "average_views" ||
								stat.name === "average_shorts_views"
							) {
								Icon = Play;
							} else if (stat.name === "average_shares") {
								Icon = Forward;
							} else if (stat.name === "total_views") {
								Icon = Eye;
							}

							let value = formatFollowerCount(Math.round(stat.value));
							if (stat.display === "percent") {
								value = (stat.value / 100).toLocaleString("en-US", {
									style: "percent",
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								});
							}

							return (
								<StatCard
									key={`youtube-stat-${i}`}
									title={stat.title}
									value={value}
									icon={<Icon className="text-theme-text-secondary h-5 w-5" />}
									isDetailsEnabled={stat.source !== "custom"}
									stat={stat}
								/>
							);
						}
					})}
				</div>
				<div
					className={cx(
						"flex h-fit flex-wrap items-center gap-y-4",
						isPreview && !isMobilePreview && "lg:flex",
						customStats &&
							customStats.length === 0 &&
							"hidden md:hidden lg:hidden",
					)}
				>
					{customStats
						? customStats.map((stat, i) => {
								return (
									<div
										key={`youtube-stat-${i}`}
										className={cx(
											"w-full md:basis-1/2 md:gap-4 md:odd:pr-2 md:odd:last:basis-full md:odd:last:p-0 md:even:pl-2",
											isPreview &&
												!isMobilePreview &&
												"lg:basis-full lg:odd:p-0 lg:even:p-0",
											isMobilePreview && "odd:pr-0 even:pl-0",
										)}
									>
										<StatCard
											title={stat.title}
											value={stat.value.toString()}
											isDetailsEnabled={true}
											stat={stat}
										/>
									</div>
								);
							})
						: null}
				</div>
				<div
					className={cx(
						"flex flex-col flex-wrap-reverse items-start gap-y-4 md:flex-row lg:flex-nowrap lg:gap-4",
						isPreview && "lg:flex-wrap-reverse lg:gap-0 lg:gap-y-4",
						isMobilePreview && "md:flex-wrap-reverse lg:flex-wrap-reverse",
					)}
				>
					{genderStat && genderStat.enabled ? (
						<div
							className={cx(
								"w-full",
								!isPreview && "md:basis-1/2 lg:basis-auto",
								isPreview && "lg:basis-1/2",
								isMobilePreview && "md:basis-full lg:basis-full",
							)}
						>
							<div
								className={cx(
									"w-full md:pr-2 lg:pr-0",
									isPreview && !isMobilePreview && "lg:pr-2",
								)}
							>
								<PieStat
									theme={theme}
									stat={genderStat}
									platform="instagram"
									isPreview={isPreview}
								/>
							</div>
						</div>
					) : null}
					{ageStat && ageStat.enabled ? (
						<div
							className={cx(
								"w-full",
								!isPreview && "md:basis-1/2 lg:basis-auto",
								isPreview && "lg:basis-1/2",
								isMobilePreview && "md:basis-full lg:basis-full",
							)}
						>
							<div
								className={cx(
									"w-full md:pl-2 lg:pl-0",
									isPreview && !isMobilePreview && "lg:pl-2",
								)}
							>
								<BarStat
									isPreview={isPreview}
									stat={ageStat}
									truncateKey="45+"
									demographic="age"
								/>
							</div>
						</div>
					) : null}
					{countryStat && countryStat.enabled ? (
						<div
							className={cx(
								"w-full md:basis-full lg:basis-auto",
								isPreview && "md:basis-full lg:basis-full",
							)}
						>
							<BarStat
								isPreview={isPreview}
								stat={countryStat}
								sortBy="value"
								demographic="location"
							/>
						</div>
					) : null}
				</div>
			</div>
			<div className="flex h-full w-full flex-col gap-4">
				<div className="flex w-full items-center justify-between">
					<p className="text-theme-text-primary text-xl font-semibold leading-[1.30em]">
						Long-form Videos
					</p>
					{updatedAt ? (
						<div className="flex items-center gap-1.5">
							<p className="text-theme-text-primary text-xs font-semibold leading-[1.30em] opacity-50">
								Updated{" "}
								<TimeBetween
									from={updatedAt}
									isMobilePreview={isMobilePreview}
								/>{" "}
								by
							</p>
							<JulyLogo className="text-theme-text-primary h-4 w-auto" />
						</div>
					) : null}
				</div>
				<div
					className={cx(
						"flex h-fit flex-wrap items-center gap-y-4 lg:hidden",
						isPreview && !isMobilePreview && "lg:flex",
						isMobilePreview && "hidden",
						displayStatsGroup2.length === 0 && "hidden md:hidden lg:hidden",
					)}
				>
					{displayStatsGroup2.map((stat, i) => {
						if (stat.type !== "value") {
							return null;
						} else {
							let Icon = User;
							if (stat.name === "engagement_rate") {
								Icon = Chart;
							} else if (
								stat.name === "average_likes" ||
								stat.name === "average_shorts_likes"
							) {
								Icon = HeartFull;
							} else if (stat.name === "average_comments") {
								Icon = Chat;
							}

							let value = formatFollowerCount(Math.round(stat.value));
							if (stat.display === "percent") {
								value = (stat.value / 100).toLocaleString("en-US", {
									style: "percent",
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								});
							}

							return (
								<div
									key={`youtube-stat-${i}`}
									className={cx(
										"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:odd:last:p-0 md:even:pl-2 lg:odd:p-0 lg:even:p-0",
										isPreview &&
											!isMobilePreview &&
											"lg:basis-1/2 lg:odd:pr-2 lg:even:pl-2",
										isMobilePreview && "odd:pr-0 even:pl-0",
									)}
								>
									<StatCard
										title={stat.title}
										value={value}
										icon={
											<Icon className="text-theme-text-secondary h-5 w-5" />
										}
										isDetailsEnabled={stat.source !== "custom"}
										stat={stat}
									/>
								</div>
							);
						}
					})}
				</div>
				<div
					className={cx(
						"hidden h-fit items-center gap-4 lg:flex",
						isPreview && !isMobilePreview && "lg:hidden",
						isMobilePreview && "flex flex-col",
						displayStatsGroup2.length === 0 && "hidden md:hidden lg:hidden",
					)}
				>
					{displayStatsGroup2.map((stat, i) => {
						if (stat.type !== "value") {
							return null;
						} else {
							let Icon = User;
							if (stat.name === "engagement_rate") {
								Icon = Chart;
							} else if (
								stat.name === "average_likes" ||
								stat.name === "average_shorts_likes"
							) {
								Icon = HeartFull;
							} else if (stat.name === "average_comments") {
								Icon = Chat;
							} else if (
								stat.name === "average_views" ||
								stat.name === "average_shorts_views"
							) {
								Icon = Play;
							} else if (stat.name === "average_shares") {
								Icon = Forward;
							}

							let value = formatFollowerCount(Math.round(stat.value));
							if (stat.display === "percent") {
								value = (stat.value / 100).toLocaleString("en-US", {
									style: "percent",
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								});
							}

							return (
								<StatCard
									key={`youtube-stat-${i}`}
									title={stat.title}
									value={value}
									icon={<Icon className="text-theme-text-secondary h-5 w-5" />}
									isDetailsEnabled={stat.source !== "custom"}
									stat={stat}
								/>
							);
						}
					})}
				</div>
			</div>
			{isShortsVisible ? (
				<div className="flex h-full w-full flex-col gap-4">
					<div className="flex w-full items-center justify-between">
						<p className="text-theme-text-primary text-xl font-semibold leading-[1.30em]">
							YouTube Shorts
						</p>
						{updatedAt ? (
							<div className="flex items-center gap-1.5">
								<p className="text-theme-text-primary text-xs font-semibold leading-[1.30em] opacity-50">
									Updated{" "}
									<TimeBetween
										from={updatedAt}
										isMobilePreview={isMobilePreview}
									/>{" "}
									by
								</p>
								<JulyLogo className="text-theme-text-primary h-4 w-auto" />
							</div>
						) : null}
					</div>
					<div
						className={cx(
							"flex h-fit flex-wrap items-center gap-y-4 lg:hidden",
							isPreview && !isMobilePreview && "lg:flex",
							isMobilePreview && "hidden",
							displayStatsGroup3.length === 0 && "lg:hiden hidden md:hidden",
						)}
					>
						{displayStatsGroup3.map((stat, i) => {
							if (stat.type !== "value") {
								return null;
							} else {
								let Icon = User;
								if (stat.name === "engagement_rate") {
									Icon = Chart;
								} else if (
									stat.name === "average_likes" ||
									stat.name === "average_shorts_likes"
								) {
									Icon = HeartFull;
								} else if (stat.name === "average_comments") {
									Icon = Chat;
								}

								let value = formatFollowerCount(Math.round(stat.value));
								if (stat.display === "percent") {
									value = (stat.value / 100).toLocaleString("en-US", {
										style: "percent",
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									});
								}

								return (
									<div
										key={`youtube-stat-${i}`}
										className={cx(
											"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:odd:last:p-0 md:even:pl-2 lg:odd:p-0 lg:even:p-0",
											isPreview &&
												!isMobilePreview &&
												"lg:basis-1/2 lg:odd:pr-2 lg:even:pl-2",
											isMobilePreview && "odd:pr-0 even:pl-0",
										)}
									>
										<StatCard
											title={stat.title}
											value={value}
											icon={
												<Icon className="text-theme-text-secondary h-5 w-5" />
											}
											isDetailsEnabled={stat.source !== "custom"}
											stat={stat}
										/>
									</div>
								);
							}
						})}
					</div>
					<div
						className={cx(
							"hidden h-fit items-center gap-4 lg:flex",
							isPreview && !isMobilePreview && "lg:hidden",
							isMobilePreview && "flex flex-col",
							displayStatsGroup3.length === 0 && "lg:hiden hidden md:hidden",
						)}
					>
						{displayStatsGroup3.map((stat, i) => {
							if (stat.type !== "value") {
								return null;
							} else {
								let Icon = User;
								if (stat.name === "engagement_rate") {
									Icon = Chart;
								} else if (
									stat.name === "average_likes" ||
									stat.name === "average_shorts_likes"
								) {
									Icon = HeartFull;
								} else if (stat.name === "average_comments") {
									Icon = Chat;
								} else if (
									stat.name === "average_views" ||
									stat.name === "average_shorts_views"
								) {
									Icon = Play;
								} else if (stat.name === "average_shares") {
									Icon = Forward;
								}

								let value = formatFollowerCount(Math.round(stat.value));
								if (stat.display === "percent") {
									value = (stat.value / 100).toLocaleString("en-US", {
										style: "percent",
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									});
								}

								return (
									<StatCard
										key={`youtube-stat-${i}`}
										title={stat.title}
										value={value}
										icon={
											<Icon className="text-theme-text-secondary h-5 w-5" />
										}
										isDetailsEnabled={stat.source !== "custom"}
										stat={stat}
									/>
								);
							}
						})}
					</div>
				</div>
			) : null}
		</div>
	);
};
