import { useStore } from "zustand";
import { type DealStore, createDealStore } from "../../utils/store/deal-store";
import { createContext, ReactNode, useContext, useRef } from "react";

export type DealStoreApi = ReturnType<typeof createDealStore>;

export const DealStoreContext = createContext<DealStoreApi | undefined>(
	undefined,
);

export interface DealStoreProviderProps {
	children: ReactNode;
}

export const DealStoreProvider: React.FC<DealStoreProviderProps> = ({
	children,
}) => {
	const storeRef = useRef<DealStoreApi>();
	if (!storeRef.current) {
		storeRef.current = createDealStore();
	}

	return (
		<DealStoreContext.Provider value={storeRef.current}>
			{children}
		</DealStoreContext.Provider>
	);
};

export const useDealStore = <T,>(selector: (store: DealStore) => T): T => {
	const dealStoreContext = useContext(DealStoreContext);

	if (!dealStoreContext) {
		throw new Error(`useDealStore must be used within DealStoreProvider`);
	}

	return useStore(dealStoreContext, selector);
};
