import { z } from "zod";
import { Platform } from "./profile";

export const CreatorCardSection = z.enum([
	"about",
	"stats",
	"rates",
	"partnerships",
]);
export type CreatorCardSection = z.infer<typeof CreatorCardSection>;

export const CreatorCardDisabledStatsEnum = z.enum([
	"followers",
	"averageLikes",
	"averageComments",
	"engagementRate",
	"averageLiveViews",
	"gender",
	"age",
	"country",
]);
export type CreatorCardDisabledStatsType = z.infer<
	typeof CreatorCardDisabledStatsEnum
>;

const ProfileDemographicsSchema = z.object({
	male: z.record(z.number()),
	female: z.record(z.number()),
	countries: z.record(z.number()),
	gender: z
		.object({
			male: z.number(),
			female: z.number(),
		})
		.optional(),
	age: z
		.object({
			"13-17": z.number(),
			"18-24": z.number(),
			"25-34": z.number(),
			"35-44": z.number(),
			"45-54": z.number(),
			"55-64": z.number(),
			"65+": z.number(),
		})
		.partial(),
});

export const UpdateCardSectionVisibility = z.object({
	deckUuid: z.string().uuid(),
	section: CreatorCardSection,
	show: z.boolean(),
});
export type UpdateCardSectionVisibility = z.infer<
	typeof UpdateCardSectionVisibility
>;

export const CreateDeckForm = z.object({
	title: z.string(),
	description: z.string().optional(),
	folderUuid: z.string().optional(),
});
export type CreatkDeckForm = z.infer<typeof CreateDeckForm>;

export const CreatorCardPlatformStatsSchema = z.object({
	platform: z.nativeEnum(Platform),
	platformUrl: z.string().url().optional(),
	demographics: ProfileDemographicsSchema.optional(),
	username: z.string().optional(),
	displayName: z.string().optional(),
	disabledStats: z.array(CreatorCardDisabledStatsEnum).optional(),
	platformProfileUuid: z.string().uuid().optional(),
	stats: z.array(
		z.object({
			name: z.string(),
			title: z.string(),
			value: z.string(),
		}),
	),
});
export type CreatorCardPlatformStats = z.infer<
	typeof CreatorCardPlatformStatsSchema
>;

export const GetDecksSchema = z.object({
	searchInput: z.string().optional(),
	folder: z.string().uuid().optional(),
});

export type GetDecks = z.infer<typeof GetDecksSchema>;

export interface DeckFolderPreview {
	uuid: string;
	name: string;
	numDecks: number;
}

export interface DeckPreview {
	uuid: string;
	updatedAt: Date;
	title: string;
	slug: string;
	isFavorited: boolean;
	isPublished: boolean;
	creators: string[];
}

export interface DeckFolderMetadata {
	name: string;
	uuid: string;
}

export interface FolderForDeckCard {
	uuid: string;
	name: string;
}
