import {
	AgencyDeal,
	AgencyDealPreview,
	DealEntry,
	DealStatusSchema,
	DealTask,
} from "@withjuly/fabric";
import { immer } from "zustand/middleware/immer";
import { createStore } from "zustand/vanilla";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type DealState = {
	deal: AgencyDeal;
	deals: AgencyDealPreview[];
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type DealActions = {
	// Set Deal
	setDeal: (deal: AgencyDeal) => void;
	setDealUuid: (uuid: string) => void;

	// Set text inputs
	setTitle: (title: string) => void;
	setBrandName: (name: string) => void;
	setNotes: (notes: string) => void;

	// Tasks
	addTask: (task: DealTask) => void;
	updateTask: (task: DealTask) => void;
	deleteTask: (index: number) => void;

	// Entries
	addEntry: (entry: DealEntry) => void;
	updateEntry: (entry: DealEntry) => void;
	deleteEntry: (index: number) => void;
};
export type DealStore = DealState & DealActions;

export const defaultDealState = {
	deal: {
		uuid: "",
		title: "",
		brandName: "",
		entries: [],
		tasks: [],
		notes: "",
		status: DealStatusSchema.Enum.negotiating,
	},
	deals: [],
};

export const createDealStore = (initState: DealState = defaultDealState) => {
	return createStore<DealStore>()(
		immer((set) => ({
			...initState,
			setDeal: (deal) => {
				set((state) => {
					state.deal = deal;
				});
			},
			setDealUuid: (uuid) => {
				set((state) => {
					state.deal.uuid = uuid;
				});
			},
			setTitle: (title) => {
				set((state) => {
					state.deal.title = title;
				});
			},
			setBrandName: (name) => {
				set((state) => {
					state.deal.brandName = name;
				});
			},
			addTask: (task) => {
				set((state) => {
					state.deal.tasks.unshift(task);
				});
			},
			updateTask: (task) => {
				set((state) => {
					state.deal.tasks = state.deal.tasks.map((t) => {
						if (t.uuid === task.uuid) {
							return task;
						} else {
							return t;
						}
					});
				});
			},
			deleteTask: (index) => {
				set((state) => {
					state.deal.tasks.splice(index, 1);
				});
			},
			setNotes: (notes) => {
				set((state) => {
					state.deal.notes = notes;
				});
			},
			addEntry: (entry) => {
				set((state) => {
					state.deal.entries.unshift(entry);
				});
			},
			updateEntry: (entry) => {
				set((state) => {
					state.deal.entries = state.deal.entries.map((e) => {
						if (e.uuid === entry.uuid) {
							return entry;
						} else {
							return e;
						}
					});
				});
			},
			deleteEntry: (index) => {
				set((state) => {
					state.deal.entries.splice(index, 1);
				});
			},
		})),
	);
};
